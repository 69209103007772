import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {EditUserModalButon} from "../../../modal/editUserModal";
import {DeleteUserButton} from "../../../modal/deleteUserModal";

const UsersList = (props) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [listUsers, setListUsers] = useState([]);
    const [dataFromChild, setDataFromChild] = useState(0);


    const getDataFromChildEdit = (data) => {
        setDataFromChild(data);
    }

    const getDataFromChildDelete = (data) => {
        setDataFromChild(data);
    }

    const rowColored = (item) => {
        if (item%2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        }else{
            const bg = 'border border-1';
            return bg;
        }
    };   

    useEffect(() => {
        Axios.get(`${BASE_URL}/getUsersList/`).then((res) => {
            setListUsers(res.data)
            console.log({ users: res.data })
        });
    }, [dataFromChild]);

    console.log({ listUsers: listUsers })
    return (
        <div>
            <div className="users-list">
                <table className="table table-hover">
                    <thead>
                        <tr className="border border-1 table-dark">
                            <th scope="">#</th>
                            <th scope="">Username</th>
                            <th scope="">Nume</th>
                            <th scope="">Prenume</th>
                            <th scope="">Rol</th>
                            <th scope="">:</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listUsers.map((item, i) =>
                            <tr className={rowColored(i)} key={i} >
                                <th scope="row">{i + 1}</th>
                                <td>{item.username}</td>
                                <td>{item.nume}</td>
                                <td>{item.prenume}</td>
                                <td>{item.role}</td>                          
                                <td>
                                    <EditUserModalButon 
                                        userId={item.userId} 
                                        username={item.username}
                                        nume={item.nume}
                                        prenume={item.prenume}
                                        role={item.role} 
                                        idRole = {item.idRole}                                       
                                        onSubmitedEdit={getDataFromChildEdit}
                                    />
                                  {(item.idRole!=1) && <DeleteUserButton
                                        userId={item.userId}
                                        username={item.username}
                                        onSubmitedDelete={getDataFromChildDelete}
                                    />
                                  }
                                    
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className="legenda">
                <Container fluid>
                    <Row md={5} style = {{ justifyContent: 'center', paddingBottom:5 }}>LEGENDA</Row>
                    <hr />
                    <Row md={5}  style = {{ justifyContent: 'center' }}>
                        <Col>
                            <Button
                                variant="primary"
                            >
                                <i class="bi bi-pencil-square"></i>
                            </Button>
                            <br />
                            Editeaza utilizatorul
                        </Col>                        
                        <Col>
                            <Button
                                variant="danger"
                            >
                                <i class="bi bi-trash3"></i>
                            </Button><br />
                            Sterge utilizatorul
                        </Col>                        
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default UsersList;