import React from "react";
import Header from "./components/header";
import Title from "./components/title";
import ChangePassComponent from "./components/changePassComponent";
import '../../App.css';


import { checkIsLoggedIn } from "../Login";

const ChangePassword = () => {
    const isLoggedIn = checkIsLoggedIn();
    // console.log('Dashboard',{isLoggedIn})

    const userStorage = sessionStorage.getItem("user");
    // console.log('userStorage:', {userStorage})

    if (userStorage) {
        var user = JSON.parse(userStorage);
        // console.log({user1: user})
    }
    // console.log({user2: user.id})
    return(
        <div>
            <Header />
            <Title title="Schimba parola"/>
            <ChangePassComponent />
        </div>
    )
}

export default ChangePassword;