import React, {useState} from "react";
import Header from "./components/header";
import Title from "./components/title";
import AddCarComponent from "./components/addCarComponent";
import AddCarList from "./components/addCarList";
import '../../App.css'
import "bootstrap/dist/css/bootstrap.min.css";

import { checkIsLoggedIn } from "../Login";

const AddCar = () => {
    const isLoggedIn = checkIsLoggedIn();
    
    console.log('Dashboard',{isLoggedIn})

    const userStorage = sessionStorage.getItem("user");
    
    if (userStorage) {
        var user = JSON.parse(userStorage);
        // console.log({user1: user.id})
    }

    return(
        <div>
            <Header />
            <Title title = "Lista masini"/>
            <div > 
            <AddCarComponent 
                userId={user.id}
             />
            </div>
            <div class="table-responsive">
             <AddCarList />
             </div>
        </div>
    )
}

export default AddCar;