import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Axios from 'axios';

export const EditCarModalButon = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
  
    console.log({
        propsEdit: props,
    })

    const [inputFields, setInputFields] = useState({
        carId: props.carId,
 
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const validateValues = (inputValues) => {
        let errors = {};

        if (inputValues.nrAuto.length === 0) {
            errors.nrAuto = "Completeaza numarul de inmatriculare";
        }
        if (inputValues.marca.length === 0) {
            errors.marca = "Completeaza marca masinii";
        }
        if (inputValues.model.length === 0) {
            errors.model = "Completeaza modelul masinii";
        }
        if (inputValues.culoare.length === 0) {
            errors.culoare = "Completeaza culoarea masinii";
        }
        if (inputValues.vin.length === 0) {
            errors.vin = "Completeaza seria sasiului";
        }
        if (inputValues.civ.length === 0) {
            errors.civ = "Completeaza seria cartii de identitate";
        }
        if (inputValues.cilindree.length === 0) {
            errors.cilindree = "Completeaza capacitatea cilindrica";
        }
        if (inputValues.mma.length === 0) {
            errors.mma = "Completeaza masa maxima autorizata";
        }
        if (inputValues.an.length === 0) {
            errors.an = "Completeaza anul fabricatiei";
        }
        if (inputValues.tarif1.length === 0) {
            errors.tarif1 = "Completeaza tariful 1";
        }
        if (inputValues.tarif2.length === 0) {
            errors.tarif2 = "Completeaza tariful 2";
        }
        if (inputValues.tarif3.length === 0) {
            errors.tarif3 = "Completeaza tariful 3";
        }       
       
        return errors;
    };

    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    };

    const handleSubmit = (p) => {
        p.preventDefault();
        setErrors(validateValues(inputFields));
        setSubmitting(true);
        // let mess = new Date();
        // props.onSubmitedEdit(mess);
        
    };

    const finishSubmit = async() => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const userData = {
            carId: props.carId,
            nrAuto: inputFields.nrAuto,
            marca: inputFields.marca,   
            model: inputFields.model,
            culoare: inputFields.culoare,
            vin: inputFields.vin,
            civ: inputFields.civ,
            cilindree: inputFields.cilindree,
            mma: inputFields.mma,
            an: inputFields.an,
            tarif1: inputFields.tarif1,
            tarif2: inputFields.tarif2,
            tarif3: inputFields.tarif3,
        };
        console.log({ userData: userData });
        await Axios.post(`${BASE_URL}/editCar/${props.carId}`, userData).then((res) => {
            // console.log({ resStatus: res.status });
        });
        let mess = new Date();
        props.onSubmitedEdit(mess);
    };

    // console.log({ errors: errors });
    useEffect(() => {
        if (submitting) {
            finishSubmit();
            // console.log({ mes: "ok" });
        }
    }, [errors]);

    return (
        <>
            {/* {submitting ? (
                <span className="success">✓</span>
                
            ) :  */}
            <Button 
                variant="primary" 
                onClick={handleShow}
            >
                <i class="bi bi-pencil-square"></i>
            </Button>
            {/* } */}

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editeaza masina {inputFields.nrAuto}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Numar inmatriculare</Form.Label>
                            <Form.Control
                                type="text"
                                name="nrAuto"
                                value={inputFields.nrAuto = inputFields.nrAuto ? inputFields.nrAuto : props.nrAuto}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.nrAuto ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.nrAuto ? (
                            <p className="error">{errors.nrAuto}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Marca</Form.Label>
                            <Form.Control
                                type="text"
                                name="marca"
                                value={inputFields.marca = inputFields.marca ? inputFields.marca : props.marca}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.marca ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.marca ? (
                            <p className="error">{errors.marca}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Model</Form.Label>
                            <Form.Control
                                type="text"
                                name="model"
                                value={inputFields.model = inputFields.model ? inputFields.model : props.model}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.model ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.model ? (
                            <p className="error">{errors.model}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Culoare</Form.Label>
                            <Form.Control
                                type="text"
                                name="culoare"
                                value={inputFields.culoare = inputFields.culoare ? inputFields.culoare : props.culoare}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.culoare ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.culoare ? (
                            <p className="error">{errors.culoare}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Serie sasiu</Form.Label>
                            <Form.Control
                                type="text"
                                name="vin"
                                value={inputFields.vin = inputFields.vin ? inputFields.vin : props.vin}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.vin ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.vin ? (
                            <p className="error">{errors.vin}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Seria cartii de identitate</Form.Label>
                            <Form.Control
                                type="text"
                                name="civ"
                                value={inputFields.civ = inputFields.civ ? inputFields.civ : props.civ}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.civ ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.civ ? (
                            <p className="error">{errors.civ}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Capacitatea cilindrica</Form.Label>
                            <Form.Control
                                type="text"
                                name="cilindree"
                                value={inputFields.cilindree = inputFields.cilindree ? inputFields.cilindree : props.cilindree}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.cilindree ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.cilindree ? (
                            <p className="error">{errors.cilindree}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Masa maxima autorizata</Form.Label>
                            <Form.Control
                                type="text"
                                name="mma"
                                value={inputFields.mma = inputFields.mma ? inputFields.mma : props.mma}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.mma ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.mma ? (
                            <p className="error">{errors.mma}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Anul fabricatiei</Form.Label>
                            <Form.Control
                                type="text"
                                name="an"
                                value={inputFields.an = inputFields.an ? inputFields.an : props.an}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.an ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.an ? (
                            <p className="error">{errors.an}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tarif1</Form.Label>
                            <Form.Control
                                type="text"
                                name="tarif1"
                                value={inputFields.tarif1 = inputFields.tarif1 ? inputFields.tarif1 : props.tarif1}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.tarif1 ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.tarif1 ? (
                            <p className="error">{errors.tarif1}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tarif2</Form.Label>
                            <Form.Control
                                type="text"
                                name="tarif2"
                                value={inputFields.tarif2 = inputFields.tarif2 ? inputFields.tarif2 : props.tarif2}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.tarif2 ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.tarif2 ? (
                            <p className="error">{errors.tarif2}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Tarif3</Form.Label>
                            <Form.Control
                                type="text"
                                name="tarif3"
                                value={inputFields.tarif3 = inputFields.tarif3 ? inputFields.tarif3 : props.tarif3}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.tarif3 ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.tarif3 ? (
                            <p className="error">{errors.tarif3}</p>
                        ) : null}

                       
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={handleClose} type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
