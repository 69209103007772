import React, {useState} from "react";
import Header from "./components/header";
import Title from "./components/title";
import AddUserComponent from "./components/addUserComponent";
import '../../App.css';
import "bootstrap/dist/css/bootstrap.min.css";

import UsersList from "./components/addUsersListComp";

import { checkIsLoggedIn } from "../Login";

const AddCar = () => {
    const isLoggedIn = checkIsLoggedIn();
    
    console.log('Dashboard',{isLoggedIn})

    const userStorage = sessionStorage.getItem("user");
    
    if (userStorage) {
        var user = JSON.parse(userStorage);
        // console.log({user1: user.id})
    }

    return(
        <div>
            <Header />
            <Title title = "Lista utilizatori"/>
            <div >
            <AddUserComponent 
                userId={user.id}
             />
            </div>
            <div class="table-responsive">
             <UsersList 
                userId={user.id}
             />
            </div>
        </div>
    )
}

export default AddCar;