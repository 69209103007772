import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PieChart } from '@mui/x-charts/PieChart';
import { Container, Row, Col, Carousel, Form, Button } from "react-bootstrap";

const RentedCarsPerYear = () => {
    const [rentedCarsPerYear, setRentedCarsPerYear] = useState([]);
    const [error, setError] = useState(false);
    const d = new Date();
    let year = d.getFullYear();
    const [selectedYear2, setSelectedYear2] = useState(year);
    const [totalsPerCarPerYear, setTotalsPerCarPerYear] = useState([]);
    const [years, setYears] = useState([]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${BASE_URL}/years/`);
                setYears(response.data);
                // console.log({ years: response.data })
            } catch (err) {
                setError(true);
            }
        };
        fetchData();
    }, []);

    const handleYearChange2 = (event) => {
        setSelectedYear2(event.target.value);
    };

    const rowColored = (item) => {
        if (item % 2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        } else {
            const bg = 'border border-1';
            return bg;
        }
    };

    useEffect(() => {
        // console.log('useEffect', selectedMonth, selectedYear);
        const userData = {
            year: selectedYear2,
        }
        const fetchData = async () => {
            try {
                await Axios.post(`${BASE_URL}/rentedCarsPerYear`, userData).then((res) => {
                    // console.log({ res: res.data })
                    setRentedCarsPerYear(res.data);
                });
            } catch (err) {
                setError(true);
                // setLoading(false);
            }
        };
        fetchData();
    }, [selectedYear2]);

    const carsWithDetailsPerYear = []

    const getIncasatForCarIdPerYear = (carId) => {
        const casrWithSelectedIdPerYear = rentedCarsPerYear.filter(car => car.carId === carId)
        let sum = 0
        casrWithSelectedIdPerYear.forEach(car => {
            sum += parseInt(car.tarif)
        })
        return sum
    }

    const getNrInchirieriPerYear = (carId) => {
        const casrWithSelectedIdPerYear = rentedCarsPerYear.filter(car => car.carId === carId)
        return casrWithSelectedIdPerYear.length
    }

    const carsWithUniqueIdPerYear = []

    rentedCarsPerYear.forEach(car => {
        if (!carsWithUniqueIdPerYear.find(carWithUniqueIdPerYear => carWithUniqueIdPerYear.carId === car.carId)) {
            carsWithUniqueIdPerYear.push(car)
        }
    })

    carsWithUniqueIdPerYear.forEach(car => {
        const incasatPerYear = getIncasatForCarIdPerYear(car.carId)
        const nrInchirieriPerYear = getNrInchirieriPerYear(car.carId)
        carsWithDetailsPerYear.push({
            carId: car.carId,
            numarAuto: car.numarAuto,
            incasat: incasatPerYear,
            nrInchirieri: nrInchirieriPerYear
        })
    })

    if (JSON.stringify(totalsPerCarPerYear) !== JSON.stringify(carsWithDetailsPerYear)) {
        setTotalsPerCarPerYear(carsWithDetailsPerYear);

    }

    return (
        <div>
            <div className="filters" style={{ paddingTop: "30px", display: 'flex', justifyContent: 'center', aligItems: 'center' }}>
                <Card sx={{ width: 600, maxWidth: 735 }}>
                    <CardContent>
                        <FormControl className="formControl">
                            <InputLabel id="demo-simple-select-labely">Anul</InputLabel>
                            <Select
                                labelId="demo-simple-select-labelyear"
                                id="year"
                                value={selectedYear2}
                                onChange={handleYearChange2}
                            >
                                {years.map((year) => (
                                    <MenuItem
                                        key={year}
                                        value={year.year}>{year.year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2 className="text-center">Raport anual inchirieri</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="border border-1 table-dark">
                                                <th className="text-center" style={{ width: "80px" }}>Nr. Crt.</th>
                                                <th className="text-center">Nr auto</th>
                                                <th className="text-center">inchirieri</th>
                                                <th className="text-center">Incasat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalsPerCarPerYear.map((item, index) => (
                                                <tr key={index} className={rowColored(index)}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{item.numarAuto}</td>
                                                    <td className="text-center">{item.nrInchirieri}</td>
                                                    <td className="text-center">{item.incasat}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                    <CardContent>
                <PieChart

                series = {[
                    {
                    data: totalsPerCarPerYear.map((item, index) => (
                        { id: index, value: item.incasat, label: item.numarAuto }
                    ))
                    }
                ]}

                width={400}
                height={200}
                />
                </CardContent>
                </Card>
            </div>
        </div>
    );
}

export default RentedCarsPerYear;
