import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../App.css';
import Axios from 'axios';
import Cars from './cars';
import moment from 'moment';
import { Form } from 'react-bootstrap';
import CalendarBar from './calendarBar';

import '../../../App.css';


function MyCalendar(props) {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [value, onChange] = useState(new Date());
    const [totalCars, setTotalCars] = useState([]);
    const day = moment(value).format('YYYY-MM-DD');
    const time = moment(new Date()).format('HH:mm:ss');
    

    const [dataLoadChild2, setLoadData2] = useState(0);
    const [dataLoadChildRent2, setLoadDataRent2] = useState(0);
    const [dataLoadChildCancel2, setLoadDataCancel2] = useState(0);
    const [dataLoadChildDelete2, setLoadDataDelete2] = useState(0);
    const [listCars, setListCars] = useState([]);

    const userStorage = sessionStorage.getItem("user");

    if (userStorage) {
        var user = JSON.parse(userStorage);
        // console.log({user1: user})
    }

    const getDataFromChild2 = (dataFromChild2) => {
        setLoadData2(dataFromChild2);
        // console.log({dataFromChild2: dataFromChild2})
    }
    const getDataFromChildRent2 = (dataFromChildRent2) => {
        setLoadDataRent2(dataFromChildRent2);
        // console.log({dataFromChildRent2: dataFromChildRent2})
    }
    const getDataFromChildCancel2 = (dataFromChildCancel2) => {
        setLoadDataCancel2(dataFromChildCancel2);
        // console.log({dataFromChildCancel2: dataFromChildCancel2})
    }
    const getDataFromChildDelete2 = (dataFromChildDelete2) => {
        setLoadDataDelete2(dataFromChildDelete2);
        // console.log({dataFromChildDelete2: dataFromChildDelete2})
    }
// if(!listCars){

// }
const month = value.getMonth() + 1;
const year = value.getFullYear();

function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
}

const firstDayOfMonth = getFirstDayOfMonth(
    value.getFullYear(),
    value.getMonth(),
);

function getLastDayOfMonth(year, month) {
    return new Date(year, month + 1, 0);
}

const lastDayOfMonth = getLastDayOfMonth(
    value.getFullYear(),
    value.getMonth(),
);

const firstDayOfMonth2 = moment(firstDayOfMonth).format('YYYY-MM-DD');
const lastDayOfMonth2 = moment(lastDayOfMonth).format('YYYY-MM-DD');

// console.log({ month2ww: month, firstDayOfMonth2: firstDayOfMonth2, lastDayOfMonth2: lastDayOfMonth2 })

function getRomanianMonthName(month) {
    const monthNames = {
      1: 'Ianuarie',
      2: 'Februarie',
      3: 'Martie',
      4: 'Aprilie',
      5: 'Mai',
      6: 'Iunie',
      7: 'Iulie',
      8: 'August',
      9: 'Septembrie',
      10: 'Octombrie',
      11: 'Noiembrie',
      12: 'Decembrie',
    };
  
    return monthNames[month];
  }
  const romanianMonthName = getRomanianMonthName(month);

const userData = {
    // carId: props.carId,
    month: month,
    year: year,
    firstDayOfMonth: firstDayOfMonth2,
    lastDayOfMonth: lastDayOfMonth2,
};

    const dayTime = day+' '+time;
    useEffect(() => {
        Axios.post(`${BASE_URL}/carsPerDay/`, {day: day, time: time}).then((res) => {
            setTotalCars(res.data)

        });
        Axios.post(`${BASE_URL}/carListForBar/`, userData).then((res) => {
            setListCars(res.data)

    });
    }, [value, dataLoadChild2, dataLoadChildRent2, dataLoadChildCancel2, dataLoadChildDelete2]);



    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <Calendar
                        onChange={onChange}
                        value={value}
                    />
                </div>
                <div className="row justify-content-center listTitle" >
                    Grad de ocupare pe luna {romanianMonthName}
                </div>
                <div className="row justify-content-center table-responsive" style={{marginTop: "20px"}}>
                    <div>
                    <table className="table table-hover" style={{ border: "1px solid #ccc"}} >                        
                        {listCars.map((item, index) => {
                            return (
                                <tr>
                                    <td style={{textAlign:"right", fontWeight: "bold", paddingLeft: "8px"}} className='col-sm-auto'>
                                        {item.numarAuto}
                                    </td>
                                    <td key={index} className='col-sm-auto'>
                                       
                                            <CalendarBar date={value} carId={item.carId} />
                                       
                                    </td>
                                </tr>
                                )
                            }
                        )}
                        </table>
                        </div>
                </div>
                <div className="row justify-content-center table-responsive">
                    <Cars 
                        userId={user.id} 
                        idRole={user.idRole} 
                        day={day} 
                        totalCars={totalCars} 
                        onSubmitedEdit2={getDataFromChild2}
                        onSubmitedRent2={getDataFromChildRent2}
                        onSubmitedCancel2={getDataFromChildCancel2}
                        onSubmitedDelete2={getDataFromChildDelete2}
                        value={value}
                    />
                </div>
            </div>
        </>
    );
}
export default MyCalendar;