import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PieChart } from '@mui/x-charts/PieChart';
import { Container, Row, Col, Carousel, Form, Button } from "react-bootstrap";

const RentedCarsByUserPerYear = () => {
    const [rentedCarsPerYear, setRentedCarsPerYear] = useState([]);
    const [error, setError] = useState(false);
    const d = new Date();
    let year = d.getFullYear();
    const [selectedYear2, setSelectedYear2] = useState(year);
    const [totalsPerUserPerYear, setTotalsPerUserPerYear] = useState([]);
    const [years, setYears] = useState([]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${BASE_URL}/years/`);
                setYears(response.data);
                // console.log({ years: response.data })
            } catch (err) {
                setError(true);
            }
        };
        fetchData();
    }, []);

    const handleYearChange2 = (event) => {
        setSelectedYear2(event.target.value);
    }

    const rowColored = (item) => {
        if (item % 2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        } else {
            const bg = 'border border-1';
            return bg;
        }
    };

    /// raport pe an
    useEffect(() => {
        // console.log('useEffect', selectedMonth, selectedYear);
        const userData = {
            year: selectedYear2,
        }
        const fetchData = async () => {
            try {
                await Axios.post(`${BASE_URL}/rentedCarsPerYear`, userData).then((res) => {
                    console.log({ res: res.data })
                    setRentedCarsPerYear(res.data);
                });
            } catch (err) {
                setError(true);
                // setLoading(false);
            }
        };
        fetchData();
    }, [selectedYear2]);

    const carsWithDetailsPerYear = []

    const getIncasatForUserIdPerYear = (idUser) => {
        const usersWithSelectedIdPerYear = rentedCarsPerYear.filter(user => user.idUser === idUser)
        let sum = 0
        usersWithSelectedIdPerYear.forEach(user => {
            sum += parseInt(user.tarif)
        })
        return sum
    }

    const getNrInchirieriPerYear = (idUser) => {
        const usersWithSelectedIdPerYear = rentedCarsPerYear.filter(user => user.idUser === idUser)
        return usersWithSelectedIdPerYear.length
    }

    const carsWithUniqueIdPerYear = []

    rentedCarsPerYear.forEach(user => {
        if (!carsWithUniqueIdPerYear.find(carWithUniqueIdPerYear => carWithUniqueIdPerYear.idUser === user.idUser)) {
            carsWithUniqueIdPerYear.push(user)
        }
    })

    carsWithUniqueIdPerYear.forEach(user => {
        const incasatPerYear = getIncasatForUserIdPerYear(user.idUser)
        const nrInchirieriPerYear = getNrInchirieriPerYear(user.idUser)
        carsWithDetailsPerYear.push({
            idUser: user.idUser,
            nume: user.nume,
            prenume: user.prenume,
            incasat: incasatPerYear,
            nrInchirieri: nrInchirieriPerYear
        })
    })

    if (JSON.stringify(totalsPerUserPerYear) !== JSON.stringify(carsWithDetailsPerYear)) {
        setTotalsPerUserPerYear(carsWithDetailsPerYear);

    }

// console.log({totalsPerUserPerYear: totalsPerUserPerYear});
    return (
        <div>
             <div className="filters" style={{ paddingTop: "30px", display: 'flex', justifyContent: 'center', aligItems: 'center' }}>
                <Card sx={{ width: 600, maxWidth: 735 }}>
                    <CardContent>
                        <FormControl className="formControl">
                            <InputLabel id="demo-simple-select-labely">Anul</InputLabel>
                            <Select
                                labelId="demo-simple-select-labelyear"
                                id="year"
                                value={selectedYear2}
                                onChange={handleYearChange2}
                            >
                                {years.map((year) => (
                                    <MenuItem
                                        key={year}
                                        value={year.year}>{year.year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2 className="text-center">Raport anual inchirieri/agent</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="border border-1 table-dark">
                                                <th className="text-center" style={{ width: "80px" }}>Nr. Crt.</th>
                                                <th className="text-center">Agent</th>
                                                <th className="text-center">inchirieri</th>
                                                <th className="text-center">Incasat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalsPerUserPerYear.map((item, index) => (
                                                <tr key={index} className={rowColored(index)}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{item.nume} {item.prenume}</td>
                                                    <td className="text-center">{item.nrInchirieri}</td>
                                                    <td className="text-center">{item.incasat}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Container>
                    </CardContent>
                    <CardContent>
                <PieChart

                series = {[
                    {
                    data: totalsPerUserPerYear.map((item, index) => (
                        { id: index, value: item.incasat, label: item.nume + ' ' + item.prenume }
                    ))
                    }
                ]}

                width={400}
                height={200}
                />

                </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default RentedCarsByUserPerYear;