import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Axios from 'axios';

export const EditUserModalButon = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
  
    console.log({
        propsEdit: props,
    })

    const [inputFields, setInputFields] = useState({
        userId: props.userId,
        nume: props.nume,
        prenume: props.prenume,
        role: props.role, 
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const validateValues = (inputValues) => {
        let errors = {};

        if (inputValues.nume.length === 0) {
            errors.nume = "Completeaza numele utilizatorului";
        }
        if (inputValues.prenume.length === 0) {
            errors.prenume = "Completeaza prenumele utilizatorului";
        }
        if (inputValues.role.length === 0) {
            errors.role = "Completeaza rolul utilizatorului";
        }
        return errors;
    };

    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    };

    const handleSubmit = (p) => {
        p.preventDefault();
        setErrors(validateValues(inputFields));
        setSubmitting(true);
        // let mess = new Date();
        // props.onSubmitedEdit(mess);
        
    };

    const finishSubmit = async() => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const userData = {
            userId: props.userId,
            nume: inputFields.nume,
            prenume: inputFields.prenume,
            role: inputFields.role,
        };

        console.log({ userData: userData });
        await Axios.post(`${BASE_URL}/editUser/${props.userId}`, userData).then((res) => {
            // console.log({ resStatus: res.status });
        });
        let mess = new Date();
        props.onSubmitedEdit(mess);
    };

    // console.log({ errors: errors });
    useEffect(() => {
        if (submitting) {
            finishSubmit();
            // console.log({ mes: "ok" });
        }
    }, [errors]);

    return (
        <>
            {/* {submitting ? (
                <span className="success">✓</span>
                
            ) :  */}
            <Button 
                variant="primary" 
                onClick={handleShow}
            >
                <i class="bi bi-pencil-square"></i>
            </Button>
            {/* } */}

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editeaza utilizatorul {props.username}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Numele</Form.Label>
                            <Form.Control
                                type="text"
                                name="nume"
                                value={inputFields.nume = inputFields.nume ? inputFields.nume : props.nume}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.nume ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.nume ? (
                            <p className="error">{errors.nume}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Prenume</Form.Label>
                            <Form.Control
                                type="text"
                                name="prenume"
                                value={inputFields.prenume = inputFields.prenume ? inputFields.prenume : props.prenume}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.prenume ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.prenume ? (
                            <p className="error">{errors.prenume}</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="formBasicRole">
                            <Form.Label>Selecteaza rolui noului agent</Form.Label>
                            <Form.Select
                                name="role"
                                value={inputFields.role = inputFields.role ? inputFields.role : props.role}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.role ? "1px solid red" : null }}
                            >
                                <option>Selecteaza rolul</option>

                                <option value="1">Administrator</option>

                                <option value="2">Agent</option>
                            </Form.Select>
                        </Form.Group>
                        {errors.role ? (
                            <p className="error">Alege rolul!</p>
                        ) : null}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={handleClose} type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}
