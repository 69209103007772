import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Axios from 'axios';
import { useState, useEffect, useRef } from "react";
import bcrypt from 'bcryptjs';


function AddCarComponent(props) {
  const [inputFields, setInputFields] = useState({
    nume: "",
    prenume: "",
    email: "",
    parola: "",
    parolaConfirm: "",
    role: "",
  });
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  // let [reload, setReload] = useState(0);

  function onCLickShowDiv() {
    var x = document.getElementById("myDIV");
    var button = document.getElementById("myButton");
    if (x.style.display === "none") {
      x.style.display = "flex";
      button.style.display = "none";
    } else {
      x.style.display = "none";
      button.style.display = "block";
    }
  }

  const validateValues = (inputValues) => {
    let errors = {};
    if (inputValues.nume.length === 0) {
      errors.nume = "Completeaza numele";
    }
    if (inputValues.prenume.length === 0) {
      errors.prenume = "Completeaza prenume";
    }
    if (inputValues.email.length === 0) {
      errors.email = "Completeaza email-ul";
    }
    if (inputValues.parola.length === 0) {
      errors.parola = "Completeaza parola";
    }
    if (inputValues.parolaConfirm.length === 0) {
      errors.parolaConfirm = "Confirma parola!";
    }
    if (inputValues.parola !== inputValues.parolaConfirm) {
      errors.parolaNotEqual = "Parolele nu sunt identice!";      
    }
    if (inputValues.role.length === 0) {
      errors.role = "Selecteaza rolul!";

    }
    console.log({ 
      inputValues: inputValues, 
      errors: errors 
    });
    return errors;
  };

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value });
  };

  const handleSubmit = (p) => {

    p.preventDefault();
    setErrors(validateValues(inputFields));
    setSubmitting(true);
  };

  const finishSubmit = async() => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const saltRounds = 12;
    const myPlaintextPassword = inputFields.parola;
    const salt = bcrypt.genSaltSync(saltRounds);
    const hash = bcrypt.hashSync(myPlaintextPassword, salt);

    const userVerify = {
      username: inputFields.email,
    };
    await Axios.post(`${BASE_URL}/userVerify`, userVerify).then((res) => {
      // console.log({ userVerify: userVerify });
      // console.log({ res: res });
      if (res.data.length > 0) {
        setErrors({emailExists: "Exista deja un utilizator cu acest email!"});
        // alert("Exista deja un utilizator cu acest email!");
        return;
      }else{
          const userData = {
            nume: inputFields.nume,
            prenume: inputFields.prenume,
            parola: hash,
            username: inputFields.email,
            role: inputFields.role,
          };
          Axios.post(`${BASE_URL}/addUser`, userData).then((res) => {
            // console.log({ userData: userData });
          });
      
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        };
      
      } 
      )};
      
      useEffect(() => {
        if (Object.keys(errors).length === 0 && submitting) {
          finishSubmit();
        }
      }, [errors]);


  return (
    <div>
      <Button variant="success" type="submit" onClick={onCLickShowDiv} style={{marginTop:"20px"}} id="myButton">
        Adauga utilizator
      </Button>
      <div className="add-user-form-container" style={{display: "none"}} id="myDIV">
        <Form onSubmit={handleSubmit} className="bd-example">
          <Form.Group className="mb-3" controlId="formBasicNume">
            <Form.Label>Nume</Form.Label>
            <Form.Control
              type="text"
              name="nume"
              value={inputFields.nume}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.nume ? "1px solid red" : null }}
            />
            {/* <Form.Text className="text-muted">Adauga numele.</Form.Text> */}
          </Form.Group>
          {errors.nume ? (
            <p className="error">Completeaza numele!</p>
          ) : null}
          <Form.Group className="mb-3" controlId="formBasicPrenume">
            <Form.Label>Prenume</Form.Label>
            <Form.Control
              type="text"
              name="prenume"
              value={inputFields.prenume}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.prenume ? "1px solid red" : null }}
            />
            {/* <Form.Text className="text-muted">Adauga prenumele.</Form.Text> */}
          </Form.Group>
          {errors.prenume ? (
            <p className="error">Completeaza prenume!</p>
          ) : null}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="email"
              value={inputFields.email}
              autoFocus
              onChange={handleChange}
              style={{ border: (errors.email || errors.emailExists) ? "1px solid red" : null }}
            />
            {/* <Form.Text className="text-muted">Adauga prenumele.</Form.Text> */}
          </Form.Group>
          {errors.email ? (
            <p className="error">Completeaza emailul!</p>
          ) : null}
          {errors.emailExists ? (
            <p className="error">Exista deja un utilizator cu acest email!</p>
          ) : null}
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Parola</Form.Label>
            <Form.Control
              type="password"
              name="parola"
              value={inputFields.parola}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.parola ? "1px solid red" : null }}
            />
            {/* <Form.Text className="text-muted">Alege o parola.</Form.Text> */}
          </Form.Group>
          {errors.parola ? (
            <p className="error">Completeaza parola!</p>
          ) : null}
          <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
            <Form.Label>Confirma parola.</Form.Label>
            <Form.Control
              type="password"
              name="parolaConfirm"
              value={inputFields.parolaConfirm}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.parolaConfirm ? "1px solid red" : null }}
            />
            {/* <Form.Text className="text-muted">Confirma parola de mai sus.</Form.Text> */}
          </Form.Group>
          {errors.parolaConfirm ? (
            <p className="error">Completeaza/confirma parola!</p>
          ) : null}
          {errors.parolaNotEqual ? (
            <p className="error">Parolele nu sunt identice!</p>
          ) : null}
          <Form.Group className="mb-3" controlId="formBasicRole">
            <Form.Label>Selecteaza rolui noului agent</Form.Label>
            <Form.Select
              name="role"
              value={inputFields.role}
              autoFocus
              onChange={handleChange}
              style={{ border: errors.role ? "1px solid red" : null }}
            >
              <option>Selecteaza rolul</option>
              <option value="1">Administrator</option>
              <option value="2">Agent</option>
            </Form.Select>
          </Form.Group>
          {errors.role ? (
            <p className="error">Alege rolul!</p>
          ) : null}
          {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label="Check me out" />
          </Form.Group> */}

          {Object.keys(errors).length === 0 && submitting ? (
            <span className="success">Adaugat cu succes! ✓</span>
          ) :
            <Button variant="success" type="submit">
              Adauga utilizator
            </Button>
          }
        </Form>
      </div>
    </div>
  );
}

export default AddCarComponent;