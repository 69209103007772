import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getStorageUser } from "../Login";
import stampila from "../../assets/img/stampila.png";

const Pdf = (props) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const logo = require('../../assets/img/logo-rent.png');
  const storageUser = getStorageUser();

  const Print = () =>{     
    //console.log('print');  
    let printContents = document.getElementById('report').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
   document.body.innerHTML = originalContents; 
  }

  // console.log({
  //   "params in pdf": state,
  //   nume: storageUser.nume,
  //   prenume: storageUser.prenume,
  // });
  return (
    <>
        <button className='printButton no-printme' onClick={() => navigate(-1)}>INAPOI</button>
        <button className='printButton no-printme' onClick={() => window.print()}>PRINT</button>
      <div id="printme">

        <div className="wcdiv wcpage" style={{ width: "695pt", height: "970pt"}}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "10pt",
                lineHeight: "13.43pt"
              }}
            >
              1
            </span>
          </div>
          <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "80pt", textAlign: "justify", fontSize: "11px" }}>
            <div style={{ textAlign: "center", marginBottom: "20pt", fontWeight: "bold", fontSize: "12pt" }}>CONTRACT DE INCHIRIERE (AUTO)VEHICUL   Nr.{state.rentId}/{new Date().toLocaleDateString("ro-RO")}</div>
            CAP.I PARTILE CONTRACTANTE<br />
            Art.1 Locatorul si Locatarul<br />
            S.C. MOLDOVA RENTCAR  S.R.L. , CUI 39837261, J4/1336/2018, cu sediul in Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau, avand in prezentul contract calitatea de LOCATOR, denumita in continuare in prezentul contract Locator (proprietar),<br />
            si<br />
            Dl/D-na {state.client}, CNP {state.cnp}, adresa {state.adresa}, {state.localitatea}, {state.judetul}, telefon {state.telefon},<br />
            in calitate de LOCATAR (chirias), denumita in continuare in prezentul contract Locatar, avand ca temei legal art.1410-1453 si 1470-1490 Cod Civil, precum si legislatia romana in vigoare, au convenit sa incheie prezentul contract de locatiune (inchiriere auto).<br /><br />
            CAP.II OBIECTUL CONTRACTULUI<br />
            Art.2 Descrierea obiectului contractului<br />
            (1) Obiectul prezentului contract consta in darea in folosinta (inchirierea) de catre Locator,<br />
            Locatarului a (auto)vehiculului descris la al.(2) din prezentul articol, proprietatea Locatorului, denumit in continuare in prezentul contract „(auto)vehiculul”, in schimbul achitarii de catre Locatar a unui pret.<br />
            (2) (Auto)vehiculul inchiriat are urmatoarele caracteristici:<br />
            □ CATEGORIA:AUTOTURISM □ MARCA: {state.marca} □ TIPUL: {state.tipul} <br />
            □ CULOARE: {state.culoare} □ ANUL FABRICATIEI: {state.anulFabricatiei} □ NR. IDENTIFICARE: {state.vin}<br />
            □ NR. DE INMATRICULARE: {state.nrAuto} □ SERIA CIV: {state.civ} □ CC (CM3): {state.capacitateaCilindrica}<br />
            □ NR/ KM LA BORD: predare___________ primire ___________  □ MASA total maxim autorizata: {state.mma}<br />
            (3) Cartea de identitate, permisul si talonul, precum si alte caracteristici ale acestuia daca este cazul, sunt identificate in Anexa nr.1 la prezentul contract, care face parte integranta din acesta.<br />
            (4) Pe toata durata contractului Locatorul isi pastreaza dreptul de proprietate deplina asupra (auto)vehiculului inchiriat, care face obiectul prezentului contract de inchiriere.<br />
            (Auto)vehiculul este asigurat, asigurarea fiind operabila in conditiile legii si ale prezentului contract.<br />
            (5) Locatarul declara ca va folosi (auto)vehiculul inchiriat pe teritoriul tarii.<br />
            (6) In cazul in care Locatar este o societate comerciala, in sensul prezentului contract, prin „Locatar” se intelege atat societatea comerciala cat si persoana fizica desemnata de aceasta sa conduca (auto)vehiculul, si inscrisa in prezentul contract.<br /><br />
            Art.3 Predarea-primirea (auto)vehiculului<br />
            (1) Predarea-primirea (auto)vehiculului inchiriat se va face la termenul convenit de parti, dupa semnarea prezentului contract si va fi consemnata in procesul verbal de predare-primire care constituie Anexa nr.2 la prezentul contract si face parte integranta din acesta.<br />
            (2) (Auto)vehiculul se pune la dispozitia Locatarului impreuna cu copie conforma cu originalul a certificatului, cartea tehnica si toate celelalte dotari corespunzatoare (trusa medicala, cric, roata rezerva, stingator si doua triunghiuri), ce vor fi  inventariate in procesul verbal de predare-primire. <br />
            (Auto)vehiculul intruneste toate conditiile tehnice de folosire, fiind in stare normala de functionare si neavand defectiuni si lipsuri.
            (3) (Auto)vehiculul se inchiriaza cu plinul facut si va fi returnat in aceleasi conditii.<br />
            (4) Locul predarii-primirii (auto)vehiculului este in ONESTI, Str. Oituz, nr. 22.<br /><br />
            Art.4 Garantia. Service-ul<br />
            (1) Locatorul garanteaza ca (auto)vehiculul ce il inchireaza intruneste toate conditiile tehnice de folosire, fiind in stare normala de functionare si neavand defecte si lipsuri, functioneaza la capacitatea si calitatea descrisa in contract sau anexa.<br />
            (2) Locatorul nu raspunde de nefunctionarea, functionarea necorespunzatoare, sau accidentele nedorite daca acestea se datoreaza exclusiv culpei Locatarului in utilizare sau nerespectarea de catre acesta a instructiunilor de folosire, precum si in cazul in care nu s-a solicitat asistenta tehnica Locatorului.<br />
            (3) Locatarul se obliga sa se ingrijeasca de (auto)vehicul ca un bun proprietar, respectand instructiunile de utilizare si mentinandu-l in stare buna de functionare pe tot parcursul derularii prezentului contract. Cheltuielile de intretinere si utilizare cad in sarcina Locatarului.<br /><br />
            Art.5 Conditii de folosire<br />
            (1) Locatarul inchiriaza (auto)vehiculul in scopul uzului personal/interes de serviciu. Capacitatea maxima a (auto)vehiculului este de 5 LOCURI. Orice alta utilizare contrara atrage culpa Locatarului, pentru eventualele daune sau avarii produse.<br />
            (2) Locatarul are varsta implinita de 21 ani, si poseda permis de conducere categoria B, necesar utilizarii (auto)vehiculului inchiriat, cu o vechime de cel putin  6 luni.<br />
            (3) (Auto)vehiculul va fi condus de catre Locatar.<br />
            In cazul in care (auto)vehiculul a fost condus de o alta persoana decat Locatarul sau persoana indicata mai sus, raspunderea pentru pagubele cauzate printr-un eveniment rutier revine Locatarului, in solidar cu persoana implicata in eveniment, Locatorul avand posibilitatea de urmarire a oricaruia dintre cei 2, pentru recuperarea prejudiciilor.<br />
            (4) Sub sanctiunea platii de daune si a excluderii de la acoperire de catre asigurare, (auto)vehiculul nu va fi folosit si condus in urmatoarele situatii:<br />
            a) in contraventie cu legislatia in vigoare si cu orice alte reguli de circulatie;<br />
            b) in timp ce Locatarul sau persoana de la volan este sub influenta alcoolului, drogurilor, narcoticelor sau a altor substante ce pun in pericol abilitatile psihice si fizice de a reactiona;<br />
            c) in concursuri, curse sau teste auto;<br />
            d) sa nu fie dat in folosinta unei persoane neautorizate (oricare alta persoana care nu este mentionata in prezentul contract de inchiriere);<br />
            e) Locatarul se obliga sa pastreze (auto)vehiculul incuiat pe durata in care nu il foloseste si sa tina asupra sa documentele acestuia.<br />
            (5) Indiferent daca asigurarea a fost sau nu acceptata, Locatarul este responsabil pentru valoarea totala a reparatiilor in caz de dauna asupra masinii sau dauna intervenita in legatura cu (auto)vehiculul intr-unul din cazurile de mai sus.<br />
            (6) In caz de accident, Locatarul isi asuma obligatia ca, in cel mai scurt timp posibil, sa anunte atat Locatorul cat si Politia si sa se prezinte la cel mai apropiat post de Politie pentru a obtine un Proces Verbal Constatator al accidentului pana la sfarsitul perioadei de inchiriere. In cazul in care Locatarul nu prezinta acest Proces Verbal, acesta isi asuma responsabilitatea pentru reparatii  integrale. Asigurarile sunt aplicabile numai in cazul in care Locatarul prezinta actele constatatoare eliberate de Politie pentru accident. In cazul in care Locatarul nu prezinta aceste inscrisuri, el este singurul responsabil pentru acoperirea sumelor de plata si accepta sa faca aceasta plata.<br />
            (7) Locatarul se obliga sa retina pentru o perioada de 90 de zile de la data incheierii perioadei de inchiriere, toate documentele referitoare la inchiriere si la orice incidente intervenite in legatura cu (auto)vehiculul.<br />

          </div>
        </div>

        <div className="wcdiv wcpage" style={{ width: "695pt", height: "1000pt"}}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "10pt",
                lineHeight: "13.43pt"
              }}
            >
              2
            </span>
          </div>
          <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "80pt", textAlign: "justify", fontSize: "11px" }}>
          (8) Locatorul nu raspunde pentru pagubele suferite de Locatar ori de persoanele care il insotesc, in legatura cu (auto)vehiculul, pentru pierderea sau deteriorarea proprietatilor personale lasate nesupravegheate in interiorul (auto)vehiculului, daca acestea nu se datoreaza unor defectiuni legate de (auto)vehicul.<br /><br />
            Art.6 Conditii generale pentru asigurare<br />
            (1) Incheierea asigurarii de raspundere civila auto si costul acesteia cade in sarcina locatarului. In cazul in care valoarea pagubelor accidentului depaseste limita stabilita prin asigurare, locatarul este responsabil in mod direct pentru diferenta dintre valoarea totala a pagubelor produse si limita maxima de despagubire acceptata de asigurator, fiind de acord cu acoperirea integrala a valorii.<br />
            (2) Pe toata perioada derularii contractului, (auto)vehiculul va fi asigurat full casco de catre locator, costul acestei asigurari fiind suportat de catre locatar, urmand ca acesta sa efectueze platile la termenul si cuantumul stabilit de societatea de asigurare.<br />
            Locatarul va achita in cazul avarierii autoturismului din vina sa :<br />
            -	50 euro pentru avarii precum zgarieturi fine (max 10 cm);<br />
            -	100 euro pentru avarii usoare (elementre vitrate, zgarieturi fine intre 10 si 20 cm);<br />
            -	200 euro pentru avarierea autovehiculului (accident, tamponare, vandalism, pierdere cheie);
            (3) Asigurarea nu absolva Locatarul de responsabilitatea de plata pentru pagubele produse (auto)vehiculului in cazurile urmatoare:<br />
            a) in contraventie cu legislatia in vigoare si cu orice alte reguli de circulatie;<br />
            b) in timp ce Locatarul sau persoana de la volan este sub influenta alcoolului, drogurilor, narcoticelor sau a altor substante ce pun in pericol abilitatile psihice si fizice de a reactiona;<br />
            c) in concursuri, curse sau teste auto;<br />
            d) (auto)vehiculul este dat in folosinta unei persoane neautorizate (oricare alta persoana care nu este mentionata in prezentul contract de inchiriere);<br />
            e) Locatarul nu pastreaza masina incuiata pe durata in care nu o foloseste, si nu a tinut asupra sa documentele (auto)vehiculului, iar acestea au disparut;<br />
            f) deteriorarea intentionata sau din neglijenta;<br />
            g) lipsa declaratiilor sau a proceselor verbale ale Politiei in caz de accidentare a (auto)vehiculului;<br /><br />
            CAP.III PRETUL CONTRACTULUI SI MODALITATI DE PLATA<br />
            Art.7 Pretul<br />
            (1) Pretul inchirierii (auto)vehiculului este:   □ {state.pricePerDay} euro /ZI.<br />
            (2) Ca modalitati de plata, chiria se va achita dupa cum urmeaza:<br />
            □ in lei, prin virament bancar in baza facturii  emise de locator, valoarea facturii calculandu-se la cursul de schimb al BNR din data intocmirii facturii.<br />
            (3) Suma inscrisa in contract ca pret nu include valoarea unor posibile avarieri ale (auto)vehiculului produse pe durata inchirierii sau eventuale amenzi, taxe, penalitati.<br />
            (4) La returnarea (auto)vehiculului, Locatorul va completa Certificatul Tehnic si va intocmi factura finala. Locatarul este de acord sa achite si alte taxe ulterioare care ii sunt imputabile, cum ar fi: valoarea asigurarii, valoarea avarierii masinii, amenzile de circulatie sau de parcare, etc.<br />
            (5) Locatarul datoreaza pretul pentru inchiriere, in cuantumul convenit chiar daca nu Foloseste (auto)vehiculul inchiriat.<br />
            Art.8 Costuri suplimentare<br />
            Locatarul este de acord sa achite urmatoarele costuri suplimentare in cazul in care acestea vor aparea:<br />
            a) Amenzile, penalitatile pentru parcare, incalcarea regulilor de circulatie ce vizeaza (auto)vehiculul sunt responsabilitatea Locatarului pe perioada de inchiriere precum si daca s-a depasit aceasta perioada dar Locatarul nu a returnat (auto)vehiculul
            Locatorului;<br />
            b) In cazul pierderii sau deteriorarii documentelor masinii sau a cheilor acesteia, Locatarul va achita o taxa aditionala de  100 Euro.<br /><br />
            CAP.IV DURATA CONTRACTULUI<br />
            Art.9 Perioada de inchiriere<br />
            (1) Termenul de inchiriere este de {state.perioadaInchiriere} zile<br />
            (2) Durata contractului poate fi prelungita pe noi perioade de timp, numai prin acordul scris al partilor, prin act aditional la prezentul contract.<br />
            Art.10 Intarzierea in predarea (auto)vehiculului<br />
            (1) Intarzierea in predarea (auto)vehiculului de catre Locatar are urmatoarele consecinte:<br />
            a) locatarul va fi obligat la plata chiriei in cuantumul stabilit prin prezentul contract, pana in ziua returnarii (auto)vehiculului.<br />
            b) in caz de intarziere cu mai mult de ____zile fata data incetarii contractului (atunci cand intarzierea nu este anuntata telefonic) locatorul isi rezerva dreptul de a anunta imediat<br />
            Politia pentru furt de masina.<br /><br />
            CAP. V OBLIGATIILE PARTILOR<br />
            Art.11 Drepturile si obligatiile Locatorului<br />
            Drepturile si obligatiile Locatorului decurg din clauzele intregului contract, cum ar fi de exemplu (dar nu limitativ):<br />
            a) sa puna la dipozitia Locatarului, pentru efectuarea transportului pe intreaga durata a contractului, (auto)vehiculul stabilit si care sa satisfaca cerintele standard pentru transporturile interne si internationale;<br />
            b) sa predea Locatarului (auto)vehiculul, avand rezervorul de carburant plin cu toate actele necesare si dotarile aferente;<br />
            c) sa asigure FULL CASCO (auto)vehiculul pe toata durata contractului;<br />
            d) sa asigure asistenta tehnica si service, atunci cand ii este solicitata de catre Locatar;<br />
            e) sa se abtina de la orice fapt care ar avea drept consecinta tulburarea Locatarului in folosinta bunului, tulburare de fapt sau de drept. Locatorul nu raspunde de tulburarea cauzata prin faptul unui tert care nu invoca vreaun drept asupra bunului;<br />
            f) Locatorul este garant pentru evictiunea bunului si pentru viciile ascunse ale (auto)vehiculului care ii impiedica intrebuintarea. Locatorul nu raspunde insa pentru vicii care ii fac Locatarului incomoda folosinta, si pe care acesta din urma le-a putut constata la incheierea prezentului contract;<br />
            g) orice alte drepturi si obligatii ce-i revin prin lege si prezentul contract.<br /><br />
            Art.12 Drepturile si obligatiile Locatarului<br />
            Drepturile si obligatiile Locatarului decurg din clauzele intregului contract, cum ar fi de exemplu (dar nu limitativ):<br />
            a) de a avea varsta de 21 ani impliniti, carnet de conducere de categoria (auto)vehiculului ce il inchireaza cu vechimea ceruta;<br />
            b) de a plati contravaloarea chiriei (auto)vehiculului, la termenul si in conditiile stabilite in prezentul contract;<br />
            c) de a suporta costurile de intretinere si alte consturi suplimentare, conform prevederilor prezentului contract;<br />
            d) de a exploata in conditii normale (auto)vehiculul, conform instructiunilor de folosire si elaborate de fabricant si specificate in manualul de utilizare;<br />
            
          </div>
        </div>


        <div className="wcdiv wcpage" style={{ width: "695pt", height: "1000pt"}}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "10pt",
                lineHeight: "13.43pt"
              }}
            >
              3
            </span>
          </div>
          <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "80pt", textAlign: "justify", fontSize: "11px" }}>
            e) de a achita amenzile emise de autoritati pentru contraventii sau infractiuni savarsite pe perioada inchirierii de catre conducatorii (auto)vehiculului ce face obiectul prezentuluicontract;<br />
            f) de a suporta si achita la termen costul primei de asigurare de raspundere civila auto si al asigurarii full casco pentru (auto)vehiculul inchiriat.<br />
            g) de a returna (auto)vehiculul, la expirarea duratei contractului, cu rezervorul de carburant plin suportand sanctiunile pentru intarziere, conform prevederilor prezentului contract.<br />
            h) in cazul unor avarii aduse (auto)vehiculului, cu exceptia celor provocate prin forta majora, Locatarul raspunde in solidar cu asiguratorul Locatorului pentru toate cheltuielile aferente aducerii la starea initiala a (auto)vehiculului sau inlocuirii acestuia cu unul similar ca marca, tip, an de fabricatie, dotari si valoarea de piata;<br />
            i) in cazul in care asiguratorul Locatorului acopera in totalitate aceste cheltuieli, Locatarul este exonerat de raspundere civila, existand in sarcina sa raspunderea penala, in conditiile legii;<br />
            j) pentru ca pagubele produse in urma unui eveniment rutier sau furt sa poata fi recuperate prin asiguratorul Locatorului, este obligatorie declararea acestora de catre Locatar/conducatorul (auto)vehiculului organelor de politie din raza localitatii in care s-a produs evenimentul, urmand ca in caz contrar contravaloarea pagubelor sa fie suportata integral de catre Locatar/conducatorul (auto)vehiculului;<br />
            k) in cazul in care (auto)vehiculul a fost condus sub influenta alcoolului si/sau a drogurilor ori altor substante stupefiante, raspunderea in recuperarea materiala a pagubelor apartine in totalitate Locatarului/conducatorul (auto)vehiculului;<br />
            l) sa apere bunul inchiriat contra uzurparilor. Prin uzurpare se intelege orice atingere provenita de la un tert asupra posesiei bunului dat in locatiune. Locatarul are obligatia de a-l instiinta pe Locator in timp util, pentru ca acesta din urma sa fie in masura sa se apere contra incercarii de uzurpare; Daca Locatarul neglijeaza sa-l informeze pe Locator, va raspunde de prejudiciul suferit de Locator in urma neinstiintarii;<br />
            m) la incetarea perioadei de inchiriere, Locatarul va preda (auto)vehiculul inchiriat cu plinul facut, cu dotarile aferente catre Locator, cu actele ce i-au fost inmanate;<br />
            n) orice alte drepturi si obligatii ce-i revin prin lege si prezentul contract.<br />
            CAP. VI RASPUNDEREA CONTRACTUALA<br />
            Art.13 Clauza penala. Daune interese<br />
            (1) Pentru nerespectarea toatala sau partiala sau pentru executarea defectuoasa a vreuneia din clauzele contractuale, partea vinovata poate sa calculeze si sa ceara plata unor daune interese<br />
            (2) In vederea evitarii unor situatii conflictuale, partile pot hotari, de comun acord, noi termene sau modalitati de rezolvare a litigiilor.<br />
            Art.14 Forta majora si cazul fortuit<br />
            (1) Forta majora si cazul fortuit inlatura raspunderea partilor in cazul neexecutarii partiale Sau totale a obligatiilor asumate prin prezentul contract, cu conditia ca evenimentul sa fie notificat de partea care il invoca celeilalte parti, in termen de _10_(zile) de la producerea lui, si partea care il invoca sa ia toate masurile posibile in vederea limitarii consecintelor lui.<br />
            (2) Prin „forta majora” se intelege un fenomen natural sau social exterior, extraordinar, denebiruit, si care nu putea fi prevazut (ex.calamitati naturale precum inundatiile). Prin „caz fortuit”se intelege o imprejurare care isi are originea in campul de activitate a debitorului sau o imprejurare de origine externa, care nu are caracter extraordinar si poate fi prevazuta si evitata cu diligenta si grija de care este in stare omul cel mai capabil. (ex.lipsa din localitate).<br />
            (3) Pe perioada existentei cazului fortuit sau a fortei majore derularea contractului se suspenda urmad apoi a se continua dupa incetarea acestuia. Dupa incetarea cazului fortuit sau a fortei majore, daca termenul contractului nu poate fi respectat, sau daca valoarea prestatiilor se modifica datorita acestui eveniment, partile vor incheia Act aditional la prezentul contract, scris si semnat, prin care vor renegocia termenii contractului.<br />
            CAP.VII CESIUNEA SI INCETAREA CONTRACTULUI<br />
            Art.15 Modificarea si Cesiunea contractului<br />
            (1) Modificarea prezentului contract se face numai prin act aditional scris si semnat de partile contractante.<br />
            (2) Nici una din partile prezentului contract nu va putea cesiona drepturile si obligatiile ce rezulta din acesta unei terte persoane, fara acordul prealabil, dat in scris, de catre cealalta parte. Acordul scris se comunica cedentului in termen de 24 ore de la data cand acesta a cerut cesionarului consimtamantul;<br />
            (3) In cazul in care cesionarul nu raspunde in termenul sus aratat, se considera ca acesta nu a consimtit la cesiunea contractului.
            Art.16 Incetarea contractului<br />
            (1) Prezentul contract inceteaza in urmatoarele imprejurari:<br />
            a) la expirarea duratei contractului de inchiriere, daca partile nu hotarasc prelungirea acestuia;<br />
            b) prin acordul de vointa al partilor, inainte de termen;<br />
            c) orice parte poate cere rezilierea unilaterala a contractului numai dupa o notificare prealabila, cu cel putin 3 zile inainte;<br />
            d) prin reziliere de catre oricare din parti in cazul in care cealalta parte nu isi respecta culpabil obligatiile asumate. Rezilierea opereaza in conditiile legii;<br />
            e) pieirea bunului, in conditiile legii.<br />
            (2) In toate cazurile de incetare a contractului, Locatarul are obligatia sa achite toate sumele datorate Locatorului pana la data incetarii locatiunii.<br />
            CAP. VIII DISPOZITII FINALE SI TRANZITORII<br />
            Art.17 Notificari intre parti<br />
            (1) In acceptiunea partilor contractante orice notificare adresata de una dintre acestea celeilalte este valabil indeplinita daca este transmisa la adresa/sediul prevazut in partea introductiva a prezentului contract.(2) In cazul in care notificarea se face pe cale postala, ea va fi transmisa prin scrisoare recomandata cu confirmare de primire si se considera primita de destinatar la data mentionata de oficiul postal primitor pe aceasta confirmare. Daca confirmarea se transmite prin fax, ea se considera primita in prima zi lucratoare dupa cea in care a fost expediata.
            Notificarile verbale sau prin email se iau in considerare in masura in care acestea sunt confirmate si in una din modalitatile descrise mai sus.
            Art.18 Litigii. Alte dispozitii finale<br />
            (1) Litigiile izvorate din incheierea, executarea modificarea, incetarea si interpretarea<br />
            clauzelor prezentului contract se rezolva pe cale amiabila sau de catre instantele judecatoresti competente.<br />
            (2) Prezentul contract se completeaza cu prevederile legale in vigoare si impreuna cu anexele sale, care fac parte integranta din cuprinsul sau, reprezinta vointa partilor si inlatura orice intelegere verbala dintre acestea, anterioara sau ulterioara incheierii lui. In cazul in care partile isi incalca obligatiile, neexercitarea de partea care sufera vreun prejudiciu a dreptului de a cere executarea intocmai sau prin echivalent banesc a obligatiei respective, nu inseamna ca ea a renuntat la acest drept al sau.<br />
            (3) Prezentul contract contine un numar de VIII (opt) capitole,18 (optsprezece) articole,este redactat pe ___ pagini, s-a incheiat la sediul _________________, in _2_ exemplare, cate doua pentru fiecare parte, azi {new Date().toLocaleDateString("ro-RO")}, data semnarii lui. Orice alte acte aditionale la prezentul contract se vor semna tot in atatea exemplare, facandu-se mentiune despre aceastea pe marginea contractului.
            Acte anexe la prezentul contract: Anexa nr.1 – Cartea de identitate (copie) si talonul (auto)vehicului (copie);<br />
            Anexa nr.2 - Proces verbal de predare-primire (auto)vehicul.<br />
            <div className="wcdiv" style={{ top: "715pt" }}>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                LOCATOR<br />
                <b>Moldova Rent Car SRL<br />
                  {/* Nume reprezentant:</b> {storageUser.nume} {storageUser.prenume}<br /> */}
                  Nume reprezentant:</b> Busuioc Ionel<br />
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "450pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                LOCATAR<br />
                <b>Nume reprezentant:</b> {state.client}
              </span>
            </div>
            <div className="wcdiv" style={{ top: "760pt" }}>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                {/* Semnatura______________________<br /><br /><br />
                Stampila */}
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                {/* Semnatura______________________<br /><br /><br />                 */}
                <img src={stampila} style={{width:"160px", marginLeft:"70px", marginTop: "0px"}} />
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "450pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                Semnatura______________________<br /><br /><br />
                Stampila
              </span>
            </div>
            </div>
        </div>


    


        <div className="wcdiv wcpage" style={{ width: "695pt", height: "1000pt" }}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "10pt",
                lineHeight: "13.43pt"
              }}
            >
              5
            </span>
          </div>
          <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "80pt", textAlign: "justify", fontSize: "11px" }}>
            <div style={{ fontSize: "11pt", textAlign: "center", fontWeight: "bold", marginBottom: "20pt" }}>
              ANEXA Nr.2 la contractul de inchiriere (auto)vehicul nr.{state.rentId}/{new Date().toLocaleDateString("ro-RO")} <br />
              PROCES VERBAL DE PREDARE – PRIMIRE (AUTO)VEHICUL din data {new Date().toLocaleDateString("ro-RO")}</div>
            <br />
            Incheiat intre:<br />
            S.C. MOLDOVA RENTCAR  S.R.L. , CUI 39837261, J4/1336/2018, cu sediul in Onesti, str. 1Mai, Bl. 2, Sc. A, Ap. 8, avand in prezentul contract calitatea de LOCATOR, denumita in continuare in prezentul contract Locator,<br />
            si<br />
            {state.client}, cu domiciliul in {state.address}, {state.localitatea}, {state.judetul}, denumit in continuare LOCATAR.<br />
            Prin semnarea prezentului proces-verbal de predare-primire, partile declara si recunosc in mod irevocabil predarea-primirea urmatorului (auto)vehicul:<br />

            (2) (Auto)vehiculul inchiriat are urmatoarele caracteristici:<br />
            □ CATEGORIA:AUTOTURISM □ {state.marca}□ TIPUL: {state.tipul}<br />
            □ CULOARE: {state.culoare} □ ANUL FABRICATIEI:{state.anulFabricatiei} □ NR. IDENTIFICARE: {state.vin}<br />
            □ □ NR. DE INMATRICULARE: {state.nrAuto} □ SERIA CIV: {state.civ} □ CC (CM3): {state.capacitateaCilindrica}<br />
            □ NR/ KM LA BORD: predare___________ primire ___________  □ MASA total maxim autorizata: {state.mma}<br /><br />

            Locatarul confirma primirea urmatoarelor documente aferente bunului:<br />
            □ Certificat de inmatriculare<br />
            □ Carte de identitate (copie)<br />
            □ Certificat de inspectie tehnica (ITP)<br />
            □ Certificat international de inspectie tehnica (ITPI)<br />
            □ Polita de asigurare RCA<br />
            □ Polita de asigurare Casco<br />
            □ Taxa de drum<br /><br />

            Dotari si accesorii: ROATA DE REZERVA,CRIC,TRUSA MEDICALA,STINGATOR, 2 BUC TRIUNGHIURI REFLECTORIZANTE ;<br /><br />

            Locatarul confirma ca bunul mentionat mai sus:<br />
            - este in buna stare, in starea vazuta si incercata de acesta, functioneaza la parametri normali si nu are defectiuni;<br />
            - a fost inspectat de catre acesta si corespunde in totalitate specificatiilor tehnice comunicate de catre Locator;<br />
            - este livrat acestuia cu toate accesoriile, de care dispune Locatorul;<br />
            Persoana care semneaza din partea Locatorului/ Locatarului pentru confirmarea livrarii/preluarii bunului, confirma ca are autorizatie deplina sa semneze si sa predea/preia bunul intocmit la ______________________________ Str. ___________________ Nr________, la data {new Date().toLocaleDateString("ro-RO")} in doua (2) exemplare originale, fiecare dintre parti retinand cate un exemplar.<br /><br />

            Locatarul va achita in cazul avarierii autoturismului din vina sa:<br />
            -	50 euro pentru avarii precum zgarieturi fine (max 10 cm);<br />
            -	100 euro pentru avarii usoare (elementre vitrate, zgarieturi fine intre 10 si 20 cm);<br />
            -	200 euro pentru avarierea autovehiculului (accident, tamponare, vandalism,pierdere cheie);<br />
            Preluarea bunului (inclusiv actele aferente acestuia) s-a efectuat in data de: {new Date().toLocaleDateString("ro-RO")}<br />

            <div className="wcdiv" style={{ top: "600pt" }}>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                  AM PREDAT<br /><br />
                <b>Moldova Rent Car SRL<br />
                  {/* Nume reprezentant:</b> {storageUser.nume} {storageUser.prenume}<br /> */}
                  Nume reprezentant:</b> Busuioc Ionel<br />
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "450pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                AM PRIMIT<br /><br />
                <b>Nume reprezentant:</b> {state.client}
              </span>
            </div>
            <div className="wcdiv" style={{ top: "690pt" }}>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                {/* Semnatura______________________<br /><br /><br /> */}
                {/* Stampila */}
                <img src={stampila} style={{width:"180px", marginLeft:"60px", marginTop: "-35px"}} />
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "450pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                Semnatura______________________<br /><br /><br />
                Stampila
              </span>
            </div>

          </div>
        </div>

        {/* ///START GDPR */}

        <div className="wcdiv wcpage" style={{ width: "695pt", height: "1000pt" }}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "17pt",
                lineHeight: "13.43pt"
              }}
            >
              1
            </span>
          </div>
          <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "70pt", textAlign: "justify", fontSize: "11px" }}>
          <div style={{ fontSize: "11pt", textAlign: "center", fontWeight: "bold", marginBottom: "5pt" }}>
            Informare privind prelucrarea datelor cu caracter personal
          </div><br />
            Urmatoarea informare este conceputa pentru a va aduce la cunostinta aspectele cu privire la prelucrarea datelor dvs. personale si la drepturile dvs. privind aceasta prelucrare in conformitate cu Regulamentul general 2016/679 privind rotectia datelor (“GDPR”) si legislatia nationala in vigoare.<br />
            1.	Informatii generale<br />
            Noi, societatea S.C. Moldova Rentcar S.R.L., reprezentam operatorul in conformitate cu GDPR. Datele de contact ale responsabilului Societatii cu rotectia datelor sunt e-mail: moldovarentcar@yahoo.com, telefon: 0722.889.889.<br />
            2.	Cand colectam date despre dvs.?<br />
            Vom colecta datele dvs. cu caracter personal atunci cand, de exemplu:<br />
            • Sunteti clientul/beneficiarul unei activitati comerciale pe care o achizitionam/prestam;<br />
            • Cumparati sau utilizati oricare dintre produsele si serviciile noastre;<br />
            • Vizitati sau navigati pe site-ul nostru sau alte site-uri ale S.C. Moldova Rentcar S.R.L.;<br />
            • Va inregistrati pentru un anumit produs sau serviciu (exemplu: creare cont utilizator pe Site-ul https://moldovarentcar.ro/);<br />
            • Ne contactati prin diverse canale, sau ne solicitati informatii in legatura cu un produs sau serviciu, inclusiv canalele de comunicare asociate aplicatiilor social media cum ar fi Facebook, etc.;<br />
            • Ati acordat permisiunea altor societati, cum ar fi partenerii nostri comerciali sau asociatii nostri, precum si unor terti furnizori sau contractanti ai nostri, sa faca schimb de informatii cu noi cu privire la dvs.;<br />
            • Va abonati la comunicari de marketing (Neewsletters), alerte sau alte servicii oferite de noi;<br />
            • Atunci cand datele dvs. cu caracter personal sunt publice;<br />
            • De asemenea, colectam informatii de la anumite organizatii, daca este cazul si in masura in care avem temeiuri legale in acest sens. Acestea includ agentii de prevenire a fraudelor, anuare comerciale, agentii de verificare/control, agentii de calculare a facturii si prestatori servicii contractate;<br />
            • Utilizam cookie-uri (fisiere text de mici dimensiuni stocate in browser-ul dvs.) si alte tehnici cum ar fi web beacons (fisiere de tip imagine mici, transparente utilizate pentru a urmari miscarile dvs. pe site-ul nostru). Pentru detalii suplimentare in acest sens si in legatura cu modul de a opta pentru renuntarea la acestea, va rugam sa consultati sectiunea Cookie-uri pe site-ul nostru https://moldovarentcar.ro/.
            3.	Scopul prelucrarii
            Societatea prelucreaza datele dvs. cu caracter personal in urmatoarele scopuri:
            • in scopul asigurarii unei interactiuni si stabilirea unei relatii cu dvs. atunci cand avem o relatie contractuala sau un interes legitim;<br />
            • furnizarii de produse / prestarii de servicii;<br />
            • in vederea indeplinirii obligatiilor legale ale Societatii;<br />
            • in scopul urmaririi intereselor legitime ale Societatii;<br />
            • in urmatoarele scopuri, toate vizand domeniul it, in masura in care ati fost de acord cu fiecare dintre ele:
            • Furnizarea de informatii cu privire la oferte comerciale;<br />
            • Furnizarea de informatii cu privire la test drive-uri;<br />
            • Furnizarea de informatii cu privire la evenimentele Societatii;<br />
            • Furnizarea de informatii;<br />
            • Participarea la sondaje de opinie cu privire la produsele si serviciile Societatii referitor la gradul de multumire a clientilor.<br />
            4.	Categoriile de prelucrare
            In S.C. Moldova Rentcar S.R.L. sunt prelucrate urmatoarele categorii de date:
            • Numele, codul numeric personal, data nasterii, documentul de identitate, functia, adresa, e-mailul, numerele de telefon fix si/sau mobil, numarul de fax
            • Contactul dvs. cu noi, cum ar fi o nota sau inregistrarea unui apel pe care l-ati facut catre unul dintre centrele noastre de contact, un serviciu de Relatii cu Clientii, un e-mail sau o scrisoare transmise sau alte evidente ale unui contact cu noi;<br />
            • Informatii privind contul dvs., cum ar fi datele platilor datorate sau primite, abonamentele pe care le utilizati, numerele de cont sau alte informatii in legatura cu contul dvs. sau care sunt incluse in portalul nostru de gestionare a relatiei comerciale cu fiecare client;<br />
            • Informatii privind legitimitatea accesului – vom colecta parole, indicii si informatii de securitate similare utilizate pentru autentificare si accesul la conturi si servicii (pentru conturile on-line deschise de fiecare client pe web-site-ul companiei noastre);<br />
            • Preferintele dvs. pentru anumite produse, servicii si stil de viata atunci cand ne spuneti care sunt acestea sau atunci cand presupunem care sunt, in baza modului in care utilizati produsele si serviciile S.C. Moldova Rentcar S.R.L.;<br />
            • Preferintele in materie de marketing.
            • Istoricul dvs. de navigare. Daca ne-ati dat permisiunea, S.C. Moldova Rentcar S.R.L. colecteaza categoriile de site-uri web pe care ati navigat de pe mobilul, dispozitivul sau PC-ul propriu, de exemplu sport, muzica sau stiri. Cu toate acestea, nu avem un istoric al site-urilor pe care navigati, acesta fiind limitat la o scurta perioada de timp pentru a permite efectuarea conexiunii. Utilizam aceste informatii privind interesele pentru a va trimite oferte de marketing personalizate sau pentru a va arata reclame personalizate. Puteti sa renuntati la reclame conform sectiunii Cookie-uri pe site-ul nostru https://moldovarentcar.ro/ sau, in cazul marketingului, prin intermediul sectiunii Setari privind Protectia Datelor;<br />
            • Informatiile pe care le obtinem din alte surse, cum ar fi autoritati publice sau private, agentii de prevenire a fraudelor, precum si de alti furnizori de date. Acestea includ date demografice, date referitoare la interese, precum si comportamentul la navigarea online;<br />
            • Pentru imbunatatirea serviciilor oferite, convorbirea cu consultantii nostri ar putea fi inregistrata si transcrisa in format text. Daca sunteti de acord sa autorizati inregistrarea si transcrierea, continuati apelul cu consultantii nostri. In cazul in care nu doriti acest lucru, va rugam sa inchideti apelul si sa ne contactati prin celelalte mijloace de comunicare astfel cum sunt prezentate in prezentul document;<br />
            • Pentru programul de loialitate constand in oferirea unor avantaje, reduceri, discounturi pentru recompensarea fidelitatii clientului. • Nivelul serviciilor care vi se presteaza – de exemplu, probleme operationale si alte evenimente care pot afecta serviciile noastre;<br />
            • Raspunsurile la invitatii si confirmarile de participare la evenimente.<br />
            Datele personale pe care le prelucram sunt, in principal, datele tale de identificare, tranzactionale, financiare, demografice, de localizare sau alte date personale pe care le colectam direct de la dumneavoastra sau din alte surse atunci cand deveniti clientul nostru, sau cand folositi produsele sau serviciile noastre. Datele cu caracter personal sensibile dezvaluie originea dumneavoastra rasiala si etnica, opiniile politice, convingerile religioase si filozofice, afilierea sindicala, datele genetice, datele biometrice, datele referitoare la sanatate sau datele referitoare la viata sau orientarea sexuala. S.C. Moldova Rentcar S.R.L. nu colecteaza de la dumneavoastra in mod intentionat date cu caracter personal sensibile pentru a le stoca. Intentia S.C. Moldova Rentcar S.R.L. nu este de a prelucra acest tip de date decat daca ne furnizati in mod explicit date cu caracter personal sensibile (de exemplu, cerinte specifice referitoare la afilierea sindicala sau datele biometrice, in situatiile in care sunteti angajatii nostri).<br />
            5.	Temeiul juridic al prelucrarii<br />
            • Baza legala pentru colectarea si procesarea datelor dumneavoastra pentru fiecare din scopurile mentionate mai sus este:<br />
            • Contractul de furnizare sau prestari servicii la care dumneavoastra sunteti parte sau cererea dumneavoastra inainte de incheierea unui contract;<br />
            • Interesele comerciale legitime ale Societatii, de exemplu, prevenirea fraudelor, mentinerea securitatii serviciilor noastre, marketing direct si imbunatatirea serviciilor noastre. Ori de cate ori ne bazam pe acest temei juridic pentru a prelucra datele dvs., evaluam interesele noastre comerciale pentru a ne asigura ca acestea nu prevaleaza asupra drepturilor dvs. In plus, in unele cazuri aveti dreptul de a va opune acestei prelucrari. Interesul legitim al Societatii pentru a ne permite exercitarea drepturilor prevazute de lege in favoarea noastra, pentru a actiona in justitie contra oricerei activitati ilegale sau care prejudiciaza Societatea;<br />
          </div>
        </div>


        <div className="wcdiv wcpage" style={{ width: "695pt", height: "1000pt" }}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "10pt",
                lineHeight: "13.43pt"
              }}
            >
              2
            </span>
          </div>
          <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "80pt", textAlign: "justify", fontSize: "11px" }}>

          • Conformarea cu o cerinta legala imperativa, de exemplu, cerinte contabile si fiscale, care fac obiectul unor politici interne stricte (cum ar fi perioadele de pastrare), proceduri si dreptul dvs. de a restrictiona utilizarea datelor proprii, care controleaza obiectul serviciilor noastre ce vor fi prestate;<br />
            • In plus, in masura in care ati fost de acord, consimtamantul dumneavoastra. Prelucrarea datelor cu caracter personal in baza consimtamantului reprezinta un scop aditional prelucrarii datelor personale in vederea executarii contractului la care sunteti parte si nu conditioneaza furnizarea/prestarea serviciilor contractuale;<br />
            • Prelucrarea este necesara pentru indeplinirea unei sarcini care serveste unui interes public.<br />
            6.	Categoriile de destinatari catre care se pot divulga datele personale colectate:<br />
            Pentru a atinge scopurile mai sus prevazute, utilizam urmatoarele categorii de furnizori de produse si servicii/ imputerniciti:<br />
            • furnizorii implicati in mod direct/indirect in furnizarea produselor si prestarea serviciilor din domeniul online;<br />
            • autoritatile statului (inclusiv autoritati fiscale);<br />
            • contabili, auditori, experti judiciari, avocati sau alti asemenea consilieri externi ai Societatii sau catre terte persoane care furnizeaza produse si servicii companiei (furnizorii de servicii de paza, de sisteme IT, consultanti financiari);<br />
            • furnizorii Societatii implicati in mod direct/indirect in procesul de marketing si promovare.<br />
            7.	Datele colectate in mod automat<br />
            Cand vizitati Site-ul, noi colectam date in mod automat prin browserul sau dispozitivul dvs., prin utilizarea cookie-urilor si a tehnologiilor similare. Va rugam sa consultati Politica de utilizare Cookies pentru informatii suplimentare cu privire la aceste practici   https://moldovarentcar.ro//ro/cookie-policy.<br />
            8. Controalele de marketing<br />
            Daca nu mai doriti sa primiti comunicari de marketing de la noi (Newsletters), actualizati-va preferintele in profilul sau contul dvs., sau accesati sectiunea de „Unsubscribe”. Daca renuntati la primirea comunicarilor de marketing de la noi, este posibil sa va trimitem in continuare mesaje legate de comenzile dvs., sau alte mesaje legate de administrarea contului de utilizator sau actualizarea politicilor noastre , la care nu puteti renunta, atat timp cat aveti un cont de utilizator activ sau un contract/comanda in desfasurare cu noi.<br />
            9.Perioada de pastrare<br />
            Vom pastra datele dvs. personale atat timp cat este necesar, sau permis avand in vedere scopul (scopurilor) pentru care au fost obtinute si in conformitate cu cele prezentate in aceasta Nota de informare. Criteriile utilizate pentru a determina duratele perioadelor de pastrare includ:<br />
            • durata de timp in care avem o relatie in desfasurare cu dvs;<br />
            • perioada de timp necesara executarii obligatiilor si drepturilor asumate contractual, respectiv pentru o perioada egala cu perioada stabilita de cerintele legale de pastrare a informatiilor, al legislatiei fiscale/financiare, al altor legi, reglementari si/sau altor prevederi specifice;<br />
            • daca exista o obligatie legala sub a carei incidenta cadem;<br />
            • daca pastrarea este recomandabila in lumina pozitiei noastre juridice (precum punerea in aplicare a Termenilor si conditiilor, termenelor de prescriptie aplicabile, a litigiilor sau a investigatiilor de reglementare). Cand nu mai avem nevoie de datele dvs. personale, le vom sterge sau le vom distruge in siguranta. Vom analiza, de asemenea, daca si cum putem minimiza in timp datele personale pe care le folosim si daca putem anonimiza datele dvs. personale astfel incat sa nu mai poata fi asociate cu dvs. sau sa va identifice, caz in care putem utiliza aceste informatii fara alta instiintare in plus.<br />
            10.Transferul catre terte tari si masuri de siguranta<br />
            In functie de locatiile in care se afla serverele S.C. Moldova Rentcar S.R.L. sau ale imputernicitilor sai, pe care vor fi stocate datele sau in functie de locul in care se afla anumiti destinatari ai datelor, datele personale ar putea fi transferate, dupa caz, in alte state membre ale Uniunii Europene, respectiv ale Spatiului Economic European.<br />
            Cu exceptia cazului in care legea impune expres altfel sau a cazului in care este strict necesar pentru indeplinirea de catre S.C. Moldova Rentcar S.R.L. a contractului, respectiv a obligatiilor asumate, nu vom transfera datele cu caracter personal in afara Spatiului Economic European. In ipoteza in care S.C. Moldova Rentcar S.R.L., in vederea executarii contractului si a obligatiilor asumate, trebuie sa transfere date in terte tari, vom transfera numai acele date personale strict necesare in vederea executarii contractului si/sau obligatiilor asumate In alte situatii decat cele mentionate mai sus, daca, cu titlu exceptional, in vederea executarii unui contract cu un tert si/sau a unor obligatii asumate fata de terti, S.C. Moldova Rentcar S.R.L. efectueaza, din proprie initiativa sau la initiativa unui tert, un transfer de date cu caracter personal ce va priveste, intr-un stat tert, masuri de protectie determinate vor fi implementate in situatia unui astfel de transfer, iar persoanele vizate vor fi notificate in consecinta.<br />
            11. Servicii terta parte<br />
            Aceasta Nota de informare nu abordeaza, si noi nu suntem responsabili pentru, practicile de colectare, utilizare, dezvaluire sau de securitate, sau alte practici, ale oricarei terte parti, inclusiv orice terta parte care ruleaza un serviciu la care Site-ul nostru furnizeaza link-uri. Includerea unui link in cadrul Site-ului nostru nu implica faptul ca noi aprobam serviciul catre care directioneaza link-ul.<br />
            12. Utilizarea de catre minori<br />
            Site-ul si serviciile noastre nu sunt directionate pentru persoane cu o varsta de mai putin de saisprezece (16) ani, si noi nu colectam cu buna stiinta Date personale de la astfel de persoane. Daca varsta dvs. este sub varsta relevanta pentru consimtamant in jurisdictia dvs., veti avea nevoie de permisiunea parintelui sau a tutorelui dvs. legal pentru a utiliza Site-ul si serviciile noastre. Va rugam sa va consultati cu parintele sau tutorele dvs. legal inainte de a utiliza Site-ul sau serviciile noastre. Daca ne furnizati date cu caracter personal ale persoanelor cu varsta sub saisprezece (16) ani, declarati ca aveti autoritatea corespunzatoare pentru a face acest lucru si ca puteti demonstra la cererea companiei aceasta autoritate.<br />
            13. Modificari aduse<br />
            Notei de informare privind prelucrarea datelor cu caracter personal Nota noastra de informare privind prelucrarea datelor cu caracter personal se poate schimba din cand in cand si orice modificare a declaratiei va fi comunicata prin intermediul unui e-mail sau al unui anunt pe site-ul nostru. Mailul il veti primi atat timp cat sunteti inregistrati in bazele noastre de date cu o adresa de email.<br />
            14. Securitatea datelor cu caracter personal<br />
            Respectam proceduri stricte de securitate privind stocarea si dezvaluirea datelor dvs. personale si le protejam impotriva pierderii, distrugerii sau deteriorarii accidentale. Putem divulga informatiile dvs. unor terte parti de incredere pentru scopurile stabilite in aceasta Nota de informare. Solicitam tuturor partilor terte sa dispuna de masuri tehnice si operationale adecvate de securitate pentru a va proteja datele personale, in conformitate cu legislatia UE privind normele de protectie a datelor.<br /> 
            15. Drepturile dumneavoastra<br />
            Regulamentul confera persoanelor carora li se prelucreaza date personale o serie de drepturi. Astfel, pe langa drepturile existente in prezent, regasim si dreptul la portabilitatea datelor si pe cel de stergere a datelor. Pe scurt, drepturile pe care le aveti:<br />
            • Dreptul de a retrage oricand un consimtamant dat in vederea opririi unei prelucrari a datelor care se bazeaza pe consimtamantul dvs. Retragerea nu va afecta legalitatea prelucrarii pe baza consimtamantului acordat inainte de retragere, pentru executarea unui contract, in vederea indeplinirii unei obligatii legale sau in scopul urmaririi intereselor noastre legitime.<br />
            • Dreptul de acces – inseamna ca aveti dreptul de a obtine o confirmare din partea noastra ca prelucram sau nu datele cu caracter personal care va privesc si, in caz afirmativ, aveti acces la datele respective si la informatiile privind modalitatea in care sunt prelucrate aceste date.<br />
            • Dreptul la portabilitatea datelor – se refera la dreptul de a primi datele personale intr-un format structurat, utilizat in mod curent de catre noi si care poate fi citit automat, dar si la dreptul ca aceste date sa fie transmise direct altui operator, daca acest lucru este fezabil din punct de vedere tehnic.<br />
            </div>
        </div>


        <div className="wcdiv wcpage" style={{ width: "695pt", height: "1000pt" }}>
          <div
            className="wcdiv"
            style={{
              left: "56.7pt",
              top: "920pt",
              clip: "rect(0pt,538.6pt,27.86pt,-56.7pt)"
            }}
          >
            <span
              className="wcspan wctext001"
              style={{
                fontSize: "12pt",
                left: "300pt",
                top: "10pt",
                lineHeight: "13.43pt"
              }}
            >
              3
            </span>
          </div>
            <div className="wcdiv" style={{ left: "56.7pt", top: "-20pt" }}>
            <div className="wcdiv">
              <img
                className="wcimg"
                style={{
                  left: "1.5pt",
                  top: "0pt",
                  width: "222.97pt",
                  height: "66.71pt"
                }}
                src={logo}
              />
            </div>
          </div>
          <div className="wcdiv" style={{ left: "303.1pt", top: "-3pt" }}>
            <div
              className="wcdiv"
              style={{
                left: "-0.05pt",
                top: "-0.05pt",
                width: "280.4pt",
                height: "71.9pt",
                backgroundColor: "#ffffff",
                border: "solid 0.1pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "0.54pt",
                top: "0.54pt",
                width: "279.14pt",
                height: "70.64pt",
                border: "solid 0.75pt #ffffff"
              }}
            >
              <br />
            </div>
            <div
              className="wcdiv"
              style={{
                left: "7.2pt",
                top: "-10pt",
                clip: "rect(0pt,278.3pt,65.8pt,-12.2pt)"
              }}
            >
              <span
                className="wcspan wctext002"
                style={{
                  fontSize: "14pt",
                  color: "#e36c0a",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "17.09pt"
                }}
              >
                S.C. MOLDOVA RENTCAR S.R.L.
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "17.09pt", lineHeight: "14.65pt" }}
              >
                Bdul. Marasesti 77 A, Loc. Onesti, Jud. Bacau
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "31.74pt", lineHeight: "14.65pt" }}
              >
                CUI 39837261 / J4/1336/2018
              </span>{" "}
              <span
                className="wcspan wctext001"
                style={{ left: "0pt", top: "46.39pt", lineHeight: "14.65pt" }}
              >
                Tel. 0722.889.889 Email: moldovarentcar@yahoo.com
              </span>
            </div>
          </div>
          <div className="wcdiv" style={{ left: "10pt", right: "10pt", top: "80pt", textAlign: "justify", fontSize: "11px" }}>
            
            • Dreptul la opozitie – vizeaza dreptul dumneavoastra de a va opune prelucrarii datelor personale atunci cand prelucrarea este necesara pentru indeplinirea unei sarcini care serveste unui interes public sau cand are in vedere un interes legitim al operatorului. Atunci cand prelucrarea datelor cu caracter personal are drept scop marketingul direct sau prelucrari efectuate in baza consimtamantului expres, aveti dreptul de a va opune prelucrarii in orice moment.<br />
            • Dreptul la rectificare – se refera la corectarea, fara intarzieri nejustificate, a datelor cu caracter personal inexacte. Rectificarea va fi comunicata fiecarui destinatar la care au fost transmise datele, cu exceptia cazului in care acest lucru se dovedeste imposibil sau presupune eforturi disproportionate.<br />
            • Dreptul la stergerea datelor („dreptul de a fi uitat”) – inseamna ca aveti dreptul de a solicita stergerea datelor cu caracter personal, fara intarzieri nejustificate, in cazul in care se aplica unul dintre urmatoarele motive: acestea nu mai sunt necesare pentru indeplinirea scopurilor pentru care au fost colectate sau prelucrate; va retrageti consimtamantul si nu exista niciun alt temei juridic pentru prelucrare; va opuneti prelucrarii si nu exista alte motive legitime care sa prevaleze; datele cu caracter personal au fost prelucrate ilegal; datele cu caracter personal trebuie sterse pentru respectarea unei obligatii legale; datele cu caracter personal au fost colectate in legatura cu oferirea de servicii ale societatii informationale.<br />
            • Dreptul la restrictionarea prelucrarii – poate fi exercitat in cazul in care persoana contesta exactitatea datelor, pe o perioada necesara verificarii corectitudinii datelor; prelucrarea este ilegala, iar dumneavoastra va opuneti stergerii datelor cu caracter personal, solicitand in schimb restrictionarea; in cazul in care S.C. Moldova Rentcar S.R.L. nu mai are nevoie de datele cu caracter personal in scopul prelucrarii, dar persoana i le solicita pentru constatarea, exercitarea sau apararea unui drept in instanta; in cazul in care persoana s-a opus prelucrarii pentru intervalul de timp in care se verifica daca drepturile legitime ale operatorului prevaleaza asupra celor ale persoanei respective.<br />
            • Dreptul de a depune o plangere – poate fi exercitat in cazul in care considerati ca drepturile de care beneficiati in calitate de persoana vizata au fost incalcate. Va puteti adresa oricand, cu o plangere sau sesizare in acest sens, Autoritatii Nationale de Supraveghere a Prelucrarii Datelor cu Caracter Personal, cu sediul in B-dul G-ral. Gheorghe Magheru 28-30, Sector 1, cod postal 010336, Bucuresti, Romania (fax: +40 318 059 602, email: anspdcp@dataprotection.ro). De asemenea, puteti introduce o actiune in justitie, pe rolul instantelor judecatoresti competente.
            Pentru exercitarea acestor drepturi, precum si pentru orice intrebari suplimentare cu privire la aceasta informare sau in legatura cu utilizarea de catre Societate a datelor personale, va rugam sa contactati ofiterul nostru pentru protectia datelor, alegand oricare din modalitatile de comunicare descrise mai jos. Ne puteti contacta:<br />
            • Prin email – la adresa: moldovarentcar@yahoo.com; sau 0722.889.889<br />
            • Personal sau printr-o cerere trimisa prin posta – la Sediul Societatii (la adresa mentionata pe fiecare pagina in coltul din dreapta); sau:<br />
            • Telefonic – la numarul: 0722.889.889 Detalii suplimentare precum si eventuale actualizari ale acestei informari privind protectia datelor dumneavoastra – puteti gasiti pe pagina web a Societatii – sectiunea Protectia Datelor.<br />

            <div className="wcdiv" style={{ top: "650pt" }}>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                <b> Moldova Rent Car SRL<br />
                  {/* Nume reprezentant:</b> {storageUser.nume} {storageUser.prenume}<br /> */}
                  Nume reprezentant:</b> Busuioc Ionel<br />
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "450pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                <b>Nume reprezentant:</b> {state.client}
              </span>
            </div>
            <div className="wcdiv" style={{ top: "699pt" }}>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "0pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                {/* Semnatura______________________<br /> */}
                <img src={stampila} style={{width:"180px", marginLeft:"0px", marginTop: "5px"}} />
              </span>
              <span
                className="wcspan wctext001"
                style={{
                  fontSize: "12pt",
                  left: "450pt",
                  top: "0pt",
                  lineHeight: "13.43pt"
                }}
              >
                Semnatura______________________
              </span>
            </div>
          </div>
        </div>


 

        {/* ///END */}
      </div>
    </>
  )
};
export default Pdf;