import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PieChart } from '@mui/x-charts/PieChart';
import { Container, Row, Col, Carousel, Form, Button } from "react-bootstrap";

const RentedCarsPerMonth = (props) => {
    const [rentedCars, setRentedCars] = useState([]);
    const [error, setError] = useState(false);
    const d = new Date();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    const [selectedMonth, setSelectedMonth] = useState(month);
    const [selectedYear, setSelectedYear] = useState(year);


    const [totalsPerCar, setTotalsPerCar] = useState([]);
    // const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const BASE_URL = process.env.REACT_APP_BASE_URL;


    // const

    useEffect(() => {
        // console.log('useEffect', selectedMonth, selectedYear);
        const userData = {
            month: selectedMonth,
            year: selectedYear,
        }
        const fetchData = async () => {
            try {
                await Axios.post(`${BASE_URL}/rentedCarsPerMonth`, userData).then((res) => {
                    // console.log({ res: res.data })
                    setRentedCars(res.data);
                    // setLoading(false);
                    // props.getMonth({month: selectedMonth});
                    // props.getYear({year: selectedYear});
                });
            } catch (err) {
                setError(true);
                // setLoading(false);
            }
        };
        fetchData();
    }, [selectedMonth, selectedYear]);

    const months = ["Ianuarie", "Februarie", "Martie", "Aprilie", "Mai", "Junie", "Julie", "August", "Septembrie", "Octombrie", "Noiembrie", "Decembrie"]
    // useEffect(() => {
    //     const fetchData = async () => {
    //     try {
    //         const response = await axios.get(
    //         `${process.env.REACT_APP_SERVER_URL}/months`
    //         );
    //         setMonths(response.data);
    //     } catch (err) {
    //         setError(true);
    //     }
    //     };
    //     fetchData();
    // }, []);
    // const years = [2023, 2024, 2025, 2026, 2027, 2028, 2029]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.get(`${BASE_URL}/years/`);
                setYears(response.data);
                // console.log({ years: response.data })
            } catch (err) {
                setError(true);
            }
        };
        fetchData();
    }, []);

    // useEffect(() => {
    //     Axios.get(`${BASE_URL}/years/`).then((res) => {
    //         setYears(res.data)
    //         console.log({ years: res.data })
    //     });
    // }, []);

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
  
    const rowColored = (item) => {
        if (item % 2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        } else {
            const bg = 'border border-1';
            return bg;
        }
    };

    const carsWithDetails = []

    const getIncasatForCarId = (carId) => {
        const casrWithSelectedId = rentedCars.filter(car => car.carId === carId)
        let sum = 0
        casrWithSelectedId.forEach(car => {
            sum += parseInt(car.tarif)
        })
        return sum
    }

    const getNrInchirieri = (carId) => {
        const casrWithSelectedId = rentedCars.filter(car => car.carId === carId)
        return casrWithSelectedId.length
    }

    const carsWithUniqueId = []

    rentedCars.forEach(car => {
        if (!carsWithUniqueId.find(carWithUniqueId => carWithUniqueId.carId === car.carId)) {
            carsWithUniqueId.push(car)
        }
    })

    carsWithUniqueId.forEach(car => {
        const incasat = getIncasatForCarId(car.carId)
        const nrInchirieri = getNrInchirieri(car.carId)
        carsWithDetails.push({
            carId: car.carId,
            numarAuto: car.numarAuto,
            incasat: incasat,
            nrInchirieri: nrInchirieri
        })
    })

    if (JSON.stringify(totalsPerCar) !== JSON.stringify(carsWithDetails)) {
        setTotalsPerCar(carsWithDetails);
    }
   
    // console.log({ totalsPerCar: totalsPerCar})



    return (
        <div>
            {/* <h1>Rented cars per month</h1> */}
            <div className="filters" style={{ paddingTop: "30px", display: 'flex', justifyContent: 'center', aligItems: 'center' }}>
                <Card sx={{ width: 600, maxWidth: 735 }}>
                    <CardContent>
                        <FormControl className="formControl">
                            <InputLabel id="demo-simple-select-label">Luna</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="month"
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                {months.map((month, i) => (
                                    <MenuItem
                                        key={i}
                                        value={i + 1}>{month}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className="formControl">
                            <InputLabel id="demo-simple-select-labely">Anul</InputLabel>
                            <Select
                                labelId="demo-simple-select-labelyear"
                                id="year"
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                {years.map((year) => (
                                    <MenuItem
                                        key={year}
                                        value={year.year}>{year.year}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Container fluid>
                            <Row>
                                <Col>
                                    <h2 className="text-center">Raport lunar inchirieri</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr className="border border-1 table-dark">
                                                <th className="text-center" style={{ width: "80px" }}>Nr. Crt.</th>
                                                <th className="text-center">Nr auto</th>
                                                <th className="text-center">inchirieri</th>
                                                <th className="text-center">Incasat</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {totalsPerCar.map((item, index) => (
                                                <tr key={index} className={rowColored(index)}>
                                                    <td className="text-center">{index + 1}</td>
                                                    <td className="text-center">{item.numarAuto}</td>
                                                    <td className="text-center">{item.nrInchirieri}</td>
                                                    <td className="text-center">{item.incasat}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Container>

                    </CardContent>
                {/* </Card>
                <Card sx={{ width: 450, maxWidth: 735 }}> */}
             <CardContent>
                <PieChart

                series = {[
                    {
                    data: totalsPerCar.map((item, index) => (
                        { id: index, value: item.incasat, label: item.numarAuto }
                    ))
                    }
                ]}

                width={400}
                height={200}
                />
                </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default RentedCarsPerMonth;