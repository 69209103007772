import React, { useState } from "react";
import RentedCarsPerMonth from "./rentedCarsPerMonth";
import Header from "../components/header";
import Title from "../components/title";
import RentedCarsPerYear from "./rentedCarsPerYear";
import RentedCarsByUserPerYear from "./rentedCarsByUserPerYear";

const RentedCars = () => {
    // console.log({testRulareParinte: "testParinte"})

    return (
        <>
        <Header />
        <Title title = "Rapoarte"/>
        <RentedCarsPerMonth/>
        <RentedCarsPerYear />
        <RentedCarsByUserPerYear />
        </>
        );
}
export default RentedCars;