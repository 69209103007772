import React from "react";
import Header from "./components/header";
import Title from "./components/title";
import Cars from "./components/cars";
import '../../App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import MyCalendar from "./components/calendar";

import { checkIsLoggedIn } from "../Login";

const Dashboard = () => {
    const isLoggedIn = checkIsLoggedIn();
    // console.log('Dashboard',{isLoggedIn})

    const userStorage = sessionStorage.getItem("user");
    // console.log('userStorage:', {userStorage})

    if (userStorage) {
        var user = JSON.parse(userStorage);
        // console.log({user1: user})
    }
    // console.log({user2: user.id})
    return(
        <div>
            <Header />
            <Title title="Dashboard"/>
            <MyCalendar />
            {/* <Cars userId={user.id} idRole={user.idRole} /> */}
        </div>
    )
}

export default Dashboard;