import Login, {getStorageUser} from "./pages/Login";
// import { getStorageParams } from "./modal/blockCarModal";
import Dashboard from "./pages/members/dashboard";
import AddCar from "./pages/members/addCar";
import AddUser from "./pages/members/addUser";
import ChangePassword from "./pages/members/changePassword";
import Pdf from "./pages/members/pdf";
import Contracte from "./pages/members/contracte";
import RentedCars from "./pages/members/rapoarte/rentedCars";
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css"

import React, { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

export const UserContext = React.createContext();

// require('dotenv').config()
// export const getStorageParams = () => {
//   const paramsStorage = sessionStorage.getItem("params");
//   if (paramsStorage) {
//       const params = JSON.parse(paramsStorage);
//       return params;
//   }
//   return null;
// };
function App() {
  // const BASE_URL = process.env.REACT_APP_BASE_URL;
  // console.log('BASE_URL', BASE_URL);

  const storageUser = getStorageUser();
  // const storageParams = getStorageParams();
  // console.log('read storageUser', storageUser);  
  const [user, setUser] = useState(storageUser);
  // const [params, setParams] = useState(storageParams);
  // console.log('read storageParams', storageParams);  

// console.log('App');
  return (
    <div className="App">
     <BrowserRouter>
        <UserContext.Provider value={{ user: user, setUser: setUser}}>
          <Routes>
            <Route path="/members/carReportPerMonth" element={<RentedCars />} />
            <Route exact index element={ <Login /> } />
            <Route path="/members/dashboard" element={<Dashboard />} />
            <Route path="/members/addCar" element={<AddCar />} />
            <Route path="/members/addUser" element={<AddUser />} />
            <Route path="/members/changePass" element={<ChangePassword />} />
            <Route path="/members/pdf" element={<Pdf />} />
            <Route path="/members/contracte" element={<Contracte />} />



          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
