import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Axios from 'axios';
import FormSelect from 'react-bootstrap/esm/FormSelect';

 const Test = () => {
    const options = [];
    var h = "00";
    var m = "00";
    for (let i=0; i<24; i++) {
        if(i<10){
            h = "0" + i;
        }else{
            h = i;
        }
        for (let j=0; j<60; j+=30) {
            if(j<10){
                m = "0" + j;
            }else{
                m = j;
            }
            var hm = h + ":" + m;

            options.push(hm);
            console.log({hm: hm, options: options});

            
        }
    }
    return (
        <Form.Select>
        {options.map((option) =>  (
            <option key={option} value={option}>
                {option}
            </option>
        ))}
        </Form.Select>
    );
 }

function RentaCarModalButon(props) {
    const [show, setShow] = useState(false);

    const [inputFields, setInputFields] = useState({
        client: "",
        telefon: "",
        dateStart: "",
        dateEnd: "",
        timeStart: "",
        timeEnd: "",

    });
    const [errors, setErrors] = useState({});
    const [errTrue, setErrTrue] = useState(false);

    const [submitting, setSubmitting] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const carId = props.carId;
    const nrAuto = props.nrAuto;
    const userId = props.userId;

    const curentDate = new Date().toISOString().slice(0, 10);
    // console.log({ curentDate: curentDate });
    const validateValues = (inputValues) => {
        let errors = {};
        const curentDate = new Date().toISOString().slice(0, 10);
        if (inputValues.client.length === 0) {
            errors.client = "Completeaza numele clientului";
        }
        if (inputValues.telefon.length === 0) {
            errors.telefon = "Completeaza telefonul clientului";
        }
        if (!inputValues.dateStart || inputValues.dateStart < curentDate) {
            errors.dateStart = "Data de start nu poate fi mai mica dacat ziua de astazi sau necompletata";
        }
        if (!inputValues.dateEnd || inputValues.dateEnd < curentDate || inputValues.dateEnd < inputValues.dateStart) {
            errors.dateEnd = "Data de final nu poate fi mai mica dacat data de inceput sau necompletata.";
        }
        if (!inputValues.timeStart) {
            errors.timeStart = "Nu ai selectat ora de inchirierii sau este necompletata!";
        }
        if (!inputValues.timeEnd) {
            errors.timeEnd = "Nu ai selectat ora de returnarii sau este necompletata!";
        }

        return errors;
    };

    const handleChange =  (e) => {
        e.preventDefault();
        // console.log({ e: e });
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    };

    // const validateDateAndTimeStartEnd = async (value1, value2) => {
    //     const BASE_URL = process.env.REACT_APP_BASE_URL;
    //     console.log({ value1: value1, value2: value2 });

    //     const userData = {
    //         dateStart:  value1,
    //         dateEnd: value2,
    //         carId: carId,
    //     };
    //     console.log({ userDataValidate: userData });

    //     await Axios.post(`${BASE_URL}/rentedCars/`, userData).then((res) => {
    //         console.log({ resStatus: res });
    //         console.log({ resData: res.data.length });
    //         if(res.data.length > 0){
    //             setErrors({
    //                 ...errors,
    //                 errTrue: true
    //             });
    //         }
    //     });
        
    // };

    // const validateDataStartEnd = () => {
    //     validateDateAndTimeStartEnd(inputFields.dateStart, inputFields.dateEnd);
    // };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const BASE_URL = process.env.REACT_APP_BASE_URL;

        const validateResult = validateValues(inputFields);        

        const userData = {
            dateStart:  inputFields.dateStart,
            dateEnd: inputFields.dateEnd,
            timeStart: inputFields.timeStart,
            timeEnd: inputFields.timeEnd,
            carId: carId,
        };
        // console.log({ userDataValidate: userData });

        await Axios.post(`${BASE_URL}/rentedCars/`, userData).then((res) => {
            // console.log({ resStatus: res });
            console.log({ resData: res.data.length });
            if(res.data.length > 0){
                setErrTrue(
                   true
                );
            }else{
                setErrTrue(
                    false
                );
            }

        });
        // console.log({ 
        //     errors0: errors,
        //     errTrue: errTrue 
        // });
        
        setErrors({
            ...validateResult,
        });
        // console.log({ errors1: errors });
        setSubmitting(true);
    };

    // const getData = (e) => {
    //     e.preventDefault();
    //     console.log({ e: e });
    // };

    

    
    const finishSubmit = async() => {
        
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const userData = {
            dateStart: inputFields.dateStart,
            dateEnd: inputFields.dateEnd,
            carId: carId,
            client: inputFields.client,
            telefon: inputFields.telefon,
            userId: userId,
            timeStart: inputFields.timeStart,
            timeEnd: inputFields.timeEnd,
        };
        console.log({ userData: userData });
        await Axios.post(`${BASE_URL}/addRent`, userData).then((res) => {
            // console.log({ resStatus: res.status });
        });
        let mess = new Date() + "-" + inputFields.dateStart;
        props.onSubmitedRent(mess);
    };

    // console.log({ errors: errors, errTrue: errTrue });
    // console.log({ errorOBJ: Object.keys(errors).length});

    useEffect(() => {
        if (Object.keys(errors).length === 0 && !errTrue && submitting) {
            finishSubmit();
            handleClose();
            // refresPage(errors);
        }else{
            setSubmitting(false);
        }
    }, [errors, errTrue]);

    return (
        <>
            {/* {Object.keys(errors).length === 0 && submitting ? (
                <span className="success">Inchiriat cu succes! ✓</span>              
            ) :  */}
            <Button 
                variant="success" 
                onClick={handleShow}
            >
                <i class="bi bi-car-front-fill"></i>
            </Button>
            {/* } */}

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Rezerva masina {nrAuto}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Client</Form.Label>
                            <Form.Control
                                type="text"
                                name="client"
                                value={inputFields.client}
                                placeholder="Numele clientului"
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.client ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.client ? (
                            <p className="error">Completeaza numele clientului!</p>
                        ) : null}
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control
                                type="text"
                                name="telefon"
                                value={inputFields.telefon}
                                placeholder="Telefon clientului"
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.telefon ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.telefon ? (
                            <p className="error">Completeaza telefonul clientului!</p>
                        ) : null}
                        <Form.Group
                            className="mb-3"
                            // controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Data start</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateStart"
                                value={inputFields.dateStart}
                                onChange={handleChange}
                                style={{ border: errors.dateStart ? "1px solid red" : null }}
                            />
                            <Form.Control
                                type="time"
                                name="timeStart"
                                value={inputFields.timeStart}
                                onChange={handleChange}
                                style={{ border: errors.timeStart ? "1px solid red" : null }}
                            />
                            {/* <Test /> */}
                        </Form.Group>
                        {errors.dateStart ? (
                            <p className="error">Data de start este mai mica decat data de astazi sau necompletata!</p>
                        ) : null}
                        {errors.timeStart ? (
                            <p className="error">Nu ai selectat ora de inchirierii sau este necompletata!</p>
                        ) : null}
                        <Form.Group
                            className="mb-3"
                            // controlId="exampleForm.ControlTextarea11"
                        >
                            <Form.Label>Data final</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateEnd"
                                value={inputFields.dateEnd}
                                onChange={handleChange}
                                style={{ border: errors.dateEnd ? "1px solid red" : null }}
                            />
                        
                            <Form.Control
                                type="time"
                                name="timeEnd"
                                value={inputFields.timeEnd}
                                // onChange={validateDataStartEnd}
                                onChange={handleChange}
                                // onChange={(e) => {
                                //     console.log({ e: e });
                                // }}
                                style={{ border: errors.timeEnd ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errTrue ? ( 
                            <p className="error">Perioada selectata este ocupata! Alege alta perioada!</p>
                        ) : null}
                        {errors.dateEnd ? (
                            <p className="error">{errors.dateEnd}</p>
                        ) : null}
                        {errors.timeEnd ? (
                            <p className="error">{errors.timeEnd}</p>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default RentaCarModalButon;