import React from "react";
import { useState, useEffect } from "react";
import * as moment from 'moment';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import RentaCarModalButon from "../../../modal/rentaCarModal";
import { EditRentaCarModalButon } from "../../../modal/editRentCarModal";
import { CancelRentaCarModalButon } from "../../../modal/cancelRentCarModal";
import { DeleteButton } from "../../../modal/deleteButtons";
import  BlockCarModalButon  from "../../../modal/blockCarModal";
import CalendarBar from './calendarBar';

export const NewDate = (yourDate) => { const newDate = moment(yourDate).format('DD.MM.YYYY'); return newDate }
export const NewTime = (yourDate) => { const newTime = moment(yourDate).format('HH:mm:ss'); return newTime }

// export const calculatePrice = (dateEnd, dateStart, tarif1, tarif2, tarif3) => {
//     const date1 = new Date(dateStart);
//     const date2 = new Date(dateEnd);
//     const diffTime = Math.abs(date2 - date1);
//     const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//     if(diffDays > 1 && diffDays < 5){
//         const price = tarif1 * diffDays;
//         return price;
//     }
//     if(diffDays > 4 && diffDays < 15){
//         const price = tarif2 * diffDays;
//         return price;
//     }
//     if(diffDays > 14 && diffDays < 30){
//         const price = tarif3 * diffDays;
//     return price;
//     }
// }
// const refresPage = (errors) => {
//     console.log(errors);
// }

const Cars = (props) => {
    // const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const [data, setData] = useState("");
    const [dataFromChild, setLoadData] = useState(0);
    const [dataFromChildRent, setLoadDataRent] = useState(0);
    const [dataFromChildCancel, setLoadDataCancel] = useState(0);
    const [dataFromChildDelete, setLoadDataDelete] = useState(0);
    const [localDateTime, setLocalDateTime] = useState(0);

    const getDataFromChild = (dataFromChild) => {
        setLoadData(dataFromChild);
        // console.log({dataFromChild: dataFromChild})
        props.onSubmitedEdit2(dataFromChild);
    }

    const getDataFromChildRent = (dataFromChildRent) => {
        setLoadDataRent(dataFromChildRent);
        // console.log({dataFromChildRent: dataFromChildRent})
        props.onSubmitedRent2(dataFromChildRent);
    }

    const getDataFromChildCancel = (dataFromChildCancel) => {
        setLoadDataCancel(dataFromChildCancel);
        // console.log({dataFromChildCancel: dataFromChildCancel})
        props.onSubmitedCancel2(dataFromChildCancel);
    }

    const getDataFromChildDelete = (dataFromChildDelete) => {
        setLoadDataDelete(dataFromChildDelete);
        // console.log({dataFromChildDelete: dataFromChildDelete})
        props.onSubmitedDelete2(dataFromChildDelete);
    }

    const rowColored = (item) => {
        if (item%2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        }else{
            const bg = 'border border-1';
            return bg;
        }
    };

    var today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const dateTime = date + ' ' + time;
    const selectedMonth = moment(today).format('M');
    useEffect(()=>{
        setLocalDateTime(dateTime);
    },[])
    // console.log({valueCars: props.value})
    // console.log({totalCars: props.totalCars})
    return (
        <div>
            <table className="table table-hover">
                <thead>
                    <tr className="border border-1 table-dark">
                        <th scope="">#</th>
                        <th scope="">numar auto</th>
                        <th scope="">data programare</th>
                        <th scope="">data restituire</th>
                        <th scope="">loc predare</th>
                        <th scope="">loc restituire</th>
                        <th scope="">client</th>
                        <th scope="">telefon</th>
                        <th scope="">Pret</th>
                        <th scope="">agent</th>
                        <th scope="">:</th>
                        <th scope="">unelte</th>
                    </tr>
                </thead>
                <tbody>
                    {props.totalCars.rentedCarsPerDay?.map((item, i) =>
                        <>
                        <tr className={rowColored(i)} key={i}>
                            <th scope="row">{i + 1}</th>
                            {item.dataSfarsit
                                && (<>
                                    <td className="red-row-bg" id="red-row-bg">{item.numarAuto}</td>
                                    <td>{item.dataInceput ? NewDate(item.dataInceput) +' '+ NewTime(item.dataInceput) : ''}</td>
                                    <td>{item.dataSfarsit ? NewDate(item.dataSfarsit) +' '+ NewTime(item.dataSfarsit) : ''}</td>
                                    <td>{item.loc_preluare}</td>
                                    <td>{item.predare_alta_locatie}</td>
                                    <td>{item.client}</td>
                                    <td>{item.telefon}</td>
                                    <td>{item.tarif}</td>
                                    <td>{item.nume} {item.prenume}</td>
                                    <td>:</td>
                                    <td>
                                    {(!item.cnp &&  new Date(localDateTime).valueOf() < new Date(item.dataSfarsit).valueOf())
                                    && (<>
                                        <BlockCarModalButon
                                            rentId={item.rentId}
                                            carId={item.carId}
                                            userId={props.userId}
                                            client={item.client}
                                            telefon={item.telefon}
                                            dateStart={item.dataInceput}
                                            dateEnd={item.dataSfarsit}
                                            nrAuto={item.numarAuto}
                                            marca={item.marca}
                                            model={item.model}
                                            culoare={item.culoare}
                                            vin={item.vin}
                                            civ={item.civ}
                                            cilindree={item.cilindree}
                                            mma={item.mma}
                                            anulFabricatiei={item.anulFabricatiei}
                                            tarif1={item.tarif1}
                                            tarif2={item.tarif2}
                                            tarif3={item.tarif3}                                            
                                            onSubmitedCancel={getDataFromChildCancel}

                                        />
                                        <EditRentaCarModalButon
                                            key={`edit-${item.rentId}-${item.carId}-${item.userId}-${item.client}-${item.telefon}-${item.dateStart}-${item.dateEnd}-${item.nrAuto}`}
                                            rentId={item.rentId}
                                            carId={item.carId}
                                            userId={props.userId}
                                            client={item.client}
                                            telefon={item.telefon}
                                            dateStart={item.dataInceput}
                                            dateEnd={item.dataSfarsit}                                           
                                            nrAuto={item.numarAuto}
                                            onSubmitedEdit={getDataFromChild}

                                        />
                                        <CancelRentaCarModalButon
                                            rentId={item.rentId}
                                            carId={item.carId}
                                            userId={props.userId}
                                            client={item.client}
                                            telefon={item.telefon}
                                            dateStart={item.dataInceput}
                                            dateEnd={item.dataSfarsit}
                                            nrAuto={item.numarAuto}
                                            onSubmitedCancel={getDataFromChildCancel}
                                        />
                                      
                                        </>)}
                                        {(item.cnp && new Date(localDateTime).valueOf() > new Date(item.dataSfarsit).valueOf()) && 
                                            (<>
                                            <input type="button" value="Expirat" className="btn btn-danger" disabled="disabled" />
                                            </>)
                                        }
                                        
                                        {(item.cnp && new Date(localDateTime).valueOf() < new Date(item.dataSfarsit).valueOf()) && (<>    
                                            <input type="button" value="Inchiriata" className="btn btn-danger" disabled="disabled" />
                                            
                                        </>)}  

                                        {(!item.cnp && new Date(localDateTime).valueOf() > new Date(item.dataSfarsit).valueOf()) && (<>    
                                            <input type="button" value="Expirat" className="btn btn-danger" disabled="disabled" />
                                            
                                        </>)}                            
                                    </td>
                                </>
                                )                               
                            }
                        </tr>
                        </>
                    )}
                    {props.totalCars.notRentedCarsPerDay?.map((item, i) =>
                        <>
                        <tr className="border border-1" key={i}>
                            <th scope="row">{i + 1}</th>
                            {item.dataInceput
                                ? <td className="red-row-bg" id="red-row-bg">{item.numarAuto}</td>
                                : <td className="">{item.numarAuto}</td>
                            }
                            <td>{item.dataInceput ? NewDate(item.dataInceput) : ''}</td>
                            <td>{item.dataSfarsit ? NewDate(item.dataSfarsit) : ''}</td>
                            <td></td>
                            <td></td>
                            <td>{item.client}</td>
                            <td>{item.telefon}</td>
                            <td></td>
                            <td>{item.nume} {item.prenume}</td>
                            <td className="">:</td>
                            <td>
                                <RentaCarModalButon
                                    carId={item.carId}
                                    nrAuto={item.numarAuto}
                                    userId={props.userId}
                                    onSubmitedRent={getDataFromChildRent}
                                />
                                {/* {props.idRole==1 && <DeleteButton
                                    carId={item.carId}
                                    nrAuto={item.numarAuto}
                                    onSubmitedDelete={getDataFromChildDelete}
                                />} */}
                            </td>
                        </tr>
                        </>
                    )}
                </tbody>
            </table>
            <div className="legenda">
                <Container fluid>
                <Row md={5} style = {{ justifyContent: 'center', paddingBottom:5 }}>LEGENDA</Row>
                    <hr />
                    <Row md={5} style = {{ justifyContent: 'center' }}>
                        <Col>
                            <Button
                                variant="success"
                            >
                                <i class="bi bi-car-front-fill"></i>
                            </Button><br />
                            Rezerva masina
                        </Col>
                        <Col>
                            <Button
                                variant="warning"
                            >
                            <i class='bi bi-cart-check'></i>
                            </Button>
                            <br />
                            Inchiriaza masina
                        </Col>
                        <Col> 
                            <Button
                                variant="primary"
                            >
                                <i class="bi bi-pencil-square"></i>
                            </Button>
                            <br />
                            Editeaza rezervarea
                        </Col>
                        <Col>
                            <Button
                                variant="dark"
                            >
                            <i class='bi bi-x-square'></i>
                            </Button>
                            <br />
                            Anuleaza rezervarea
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )

}

export default Cars;