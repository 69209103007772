import React from "react";
import { useState, useEffect } from "react";
import * as moment from 'moment';
import Axios from "axios";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { NewDate, NewTime } from "./cars";

export const NewDateFormat = (yourDate) => { const newDate = moment(yourDate).format('YYYY-MM-DD'); return newDate }

const ContractList = (props) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [contractList, setContractList] = useState([]);
    // const [values, setValues] = useState(false);
    // const [dataFromChild, setDataFromChild] = useState(0);


    // const getDataFromChildEdit = (data) => {
    //     setDataFromChild(data);
    // }

    // const getDataFromChildDelete = (data) => {
    //     setDataFromChild(data);
    // }

    const rowColored = (item) => {
        if (item%2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        }else{
            const bg = 'border border-1';
            return bg;
        }
    };

    console.log({propsData: props.data})
    useEffect(() => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        console.log({firstDay: firstDay})
        console.log({lastDay: lastDay})
        const userData = {
            dataInceput: props.data.dataInceput ? props.data.dataInceput : NewDateFormat(firstDay),
            dataSfarsit: props.data.dataSfarsit ? props.data.dataSfarsit : NewDateFormat(lastDay),
        };
        console.log({userData: userData, start: NewDateFormat(firstDay), end : NewDateFormat(lastDay)})
        Axios.post(`${BASE_URL}/contractList/`, userData).then((res) => {
            setContractList(res.data)
            // setValues(true)
            // console.log({ cars: res.data })
        });
    }, [props.data]);

    // console.log({ listCars: listCars })
    return (
        <div>
            <div className="contracts-list-form-container">
                <table className="table table-hover">
                    <thead class="">
                        <tr className="border border-1 table-dark" style={{}}>
                            <th scope="">#</th>
                            <th scope="">numar auto</th>
                            <th scope="">client</th>
                            <th scope="">cnp</th>
                            <th scope="">telefon</th>
                            <th scope="">adresa</th>
                            <th scope="">localitate</th>
                            <th scope="">judet</th>
                            <th scope="">tarif</th>
                            <th scope="">data predare</th>
                            <th scope="">data primire</th>
                            <th scope="">loc predare</th>
                            <th scope="">alt loc primire</th>
                            <th scope="">agent</th>
                            <th scope="">foto fata</th>
                            <th scope="">foto spate</th>
                            <th scope="">foto dreapta</th>
                            <th scope="">foto stanga</th>
                            <th scope="">foto ci</th>
                            <th scope="">foto permis</th>
                        </tr>
                    </thead>
                    <tbody>
                        {contractList.map((item, i) =>
                            <tr className={rowColored(i)} key={i}>
                                <th scope="row">{i + 1}</th>
                                <td>{item.nrAuto}</td>
                                <td>{item.client}</td>
                                <td>{item.cnp}</td>
                                <td>{item.telefon}</td>
                                <td>{item.adresa}</td>
                                <td>{item.localitatea}</td>
                                <td>{item.judet}</td>
                                <td>{item.tarif} lei</td>
                                <td>{item.dataInceput ? NewDate(item.dataInceput) +' '+ NewTime(item.dataInceput) : ''}</td>
                                <td>{item.dataSfarsit ? NewDate(item.dataSfarsit) +' '+ NewTime(item.dataSfarsit) : ''}</td>
                                <td>{item.locPreluare}</td>
                                <td>{item.predareAltaLocatie}</td>
                                <td>{item.nume} {item.prenume}</td>
                                <td><img className="imageSize" src={`${BASE_URL}/uploads/${item.fatamasina}`}></img></td>
                                <td><img className="imageSize" src={`${BASE_URL}/uploads/${item.spatemasina}`}></img></td>
                                <td><img className="imageSize" src={`${BASE_URL}/uploads/${item.dreaptamasina}`}></img></td>
                                <td><img className="imageSize" src={`${BASE_URL}/uploads/${item.stangamasina}`}></img></td>
                                <td><img className="imageSize" src={`${BASE_URL}/uploads/${item.ci}`}></img></td>
                                <td><img className="imageSize" src={`${BASE_URL}/uploads/${item.permis}`}></img></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* <div className="legenda">
                <Container fluid>
                <Row md={5} style = {{ justifyContent: 'center', paddingBottom:5 }}>LEGENDA</Row>
                    <hr />
                    <Row md={5}  style = {{ justifyContent: 'center' }}>
                        <Col>
                            <Button
                                variant="primary"
                            >
                                <i class="bi bi-pencil-square"></i>
                            </Button>
                            <br />
                            Editeaza rezervarea
                        </Col>                        
                        <Col>
                            <Button
                                variant="danger"
                            >
                                <i class="bi bi-trash3"></i>
                            </Button><br />
                            Sterge masina
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </div>
    )

}

export default ContractList;