import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from "react-bootstrap/Row";
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Axios from 'axios';
import { Navigate, useNavigate } from "react-router-dom";
// import Pdf from '../pages/members/pdf';

// export const getStorageParams = () => {
//     const paramsStorage = sessionStorage.getItem("params");
//     if (paramsStorage) {
//         const params = JSON.parse(paramsStorage);
//         return params;
//     }
//     return null;
// };

function BlockCarModalButon(props) {
    const [show, setShow] = useState(false);

    const [inputFields, setInputFields] = useState({
        // dateStart: "",
        // dateEnd: "",
        // timeStart: "",
        // timeEnd: "",
        locatiePreluare: "",
        predareAltaLocatie: "",
        client: props.client,
        cnp: "",
        telefon: props.telefon,
        adresa: "",
        localitatea: "",
        judetul: "",
        fatamasina: "",
        spatemasina: "",
        dreaptamasina: "",
        stangamasina: "",
        ci: "",
        permis: "",
        tarif: "",
    });

    const [errors, setErrors] = useState({});
    // const [error, setError] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const[parametriPdfState, setParametriPdfState] = useState(null);
    const [previewImage, setPreviewImage] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [file, setFile] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const nrAuto = props.nrAuto;

    const dateStart0 = props.dateStart.split('T')[0];
    const year = dateStart0.split('-')[0];
    const month = dateStart0.split('-')[1];
    const day = dateStart0.split('-')[2];    
    const timeStart = props.dateStart.split('T')[1].split('.')[0];
    const dateStart = year +'-'+ month +'-'+ day;

    const hour = timeStart.split(':')[0];
    const minute = timeStart.split(':')[1];
    const seconds = timeStart.split(':')[2];
    const hourPlus3 = 3;
    const newH = parseInt(hour) + hourPlus3;
    if(newH < 10){
        const newHour = '0'+newH;
        var timeStart2 = newHour +':'+ minute +':'+ seconds;
    }else{
        var timeStart2 = newH +':'+ minute +':'+ seconds;
    }
    // console.log({timeStart2: timeStart2, dataStart: props.dateStart});
///////////////////////////////////////////////////////////////
    const dateEnd0 = props.dateEnd.split('T')[0];
    const yearEnd = dateEnd0.split('-')[0];
    const monthEnd = dateEnd0.split('-')[1];
    const dayEnd = dateEnd0.split('-')[2];    
    const timeEnd = props.dateEnd.split('T')[1].split('.')[0];
    const dateEnd = yearEnd +'-'+ monthEnd +'-'+ dayEnd;

    const hourE = timeEnd.split(':')[0];
    const minuteE = timeEnd.split(':')[1];
    const secondsE = timeEnd.split(':')[2];
    const hourPlus3E = 3;
    const newHourEnd = parseInt(hourE) + hourPlus3E;
    if(newHourEnd < 10){
        const newHourE = '0'+newHourEnd;
        var timeEnd2 = newHourE +':'+ minuteE +':'+ secondsE;
    }else{
        var timeEnd2 = newHourEnd +':'+ minuteE +':'+ secondsE;
    }
    // console.log({timeEnd2: timeEnd2, dataEnd: props.dateEnd});
    ///////////////////////////////////////////////////////////////
    const dateDiff = (dateEnd, dateStart) => {
        const date1 = new Date(dateStart);
        const date2 = new Date(dateEnd);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    const calculatePrice = (dateEnd, dateStart) => {
        const date1 = new Date(dateStart);
        const date2 = new Date(dateEnd);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if(diffDays >= 1 && diffDays < 5){
            const price = props.tarif1 * diffDays;
            return price;
        }
        if(diffDays > 4 && diffDays < 15){
            const price = props.tarif2 * diffDays;
            return price;
        }
        if(diffDays > 14 && diffDays < 30){
            const price = props.tarif3 * diffDays;
        return price;
        }
    }

    const price = calculatePrice(dateEnd, dateStart);

    const showPrice = (dateEnd, dateStart) => {
        const date1 = new Date(dateStart);
        const date2 = new Date(dateEnd);
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if(diffDays >= 1 && diffDays < 5){
            const pricePerDay = props.tarif1;
            return pricePerDay;
        }
        if(diffDays > 4 && diffDays < 15){
            const pricePerDay = props.tarif2;
            return pricePerDay;
        }
        if(diffDays > 14 && diffDays < 30){
            const pricePerDay = props.tarif3;
        return pricePerDay;
        }
    }
    const pricePerDay = showPrice(dateEnd, dateStart);
    const perioadaInchiriere = dateDiff(dateEnd, dateStart);
    // console.log({perioadaInchiriere: perioadaInchiriere});
    // console.log({price: price});
    // console.log({pricePerDay: pricePerDay});

    const getPdfParameters = ()=>{
        // console.log('am apleat get paramteaw')
        return {
            client: inputFields.client,
            cnp: inputFields.cnp,
            telefon: inputFields.telefon,
            adresa: inputFields.adresa,
            localitatea: inputFields.localitatea,
            judetul: inputFields.judetul,
            dateStart: inputFields.dateStart,
            dateEnd: inputFields.dateEnd,
            rentId: props.rentId,
            pricePerDay: pricePerDay,
            nrAuto: props.nrAuto,
            marca: props.marca,
            tipul: props.model,
            culoare: props.culoare,
            vin: props.vin,
            civ: props.civ,
            capacitateaCilindrica: props.cilindree,
            mma: props.mma,
            anulFabricatiei: props.anulFabricatiei,
            perioadaInchiriere: perioadaInchiriere,
        }
    }
    

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );

    // const [previewImage, setPreviewImage] = useState(null);
    // const [uploadedImage, setUploadedImage] = useState(null);
    // const [file, setFile] = useState(null);

    const navigate = useNavigate();

    const validateValues = (inputValues) => {
        let errors = {};
        // const curentDate = new Date();
        if (inputValues.client.length === 0) {
            errors.client = "Completeaza numele clientului";
        }
        if (inputValues.telefon.length === 0) {
            errors.telefon = "Completeaza telefonul clientului";
        }
        // if (!inputValues.dateStart || inputValues.dateStart < curentDate) {
        //     errors.dateStart = "Data de start nu poate fi mai mica dacat ziua de astazi sau necompletata";
        // }
        // if (!inputValues.dateEnd || inputValues.dateEnd < curentDate || inputValues.dateEnd < inputValues.dateStart) {
        //     errors.dateEnd = "Data de final nu poate fi mai mica dacat data de inceput sau necompletata.";
        // }
        // if (!inputValues.timeStart) {
        //     errors.timeStart = "Nu ai selectat ora de inchirierii sau este necompletata!";
        // }
        // if (!inputValues.timeEnd) {
        //     errors.timeEnd = "Nu ai selectat ora de returnarii sau este necompletata!";
        // }
        // if(errors.errTrue === true){
        //     errors.errTrue = "Perioada selectata este ocupata! Alege alta perioada!";
        // }
        if(inputValues.locatiePreluare.length === 0){
            errors.locatiePreluare = "Nu ai selectat locatia de preluare!";
        }
        if(inputValues.predareAltaLocatie.length === 0){
            errors.predareAltaLocatie = "Nu ai selectat locatia de predare!";
        }
        if(inputValues.adresa.length === 0){
            errors.adresa = "Nu ai completat adresa clientului!";
        }
        if(inputValues.localitatea.length === 0){
            errors.localitatea = "Nu ai completat localitatea clientului!";
        }
        if(inputValues.judetul.length === 0){
            errors.judetul = "Nu ai completat judetul clientului!";
        }
        if(!document.getElementById('fatamasina').files[0]){
            errors.fatamasina = "Nu ai adaugat poza din fata a masinii!";
        }
        if(!document.getElementById('spatemasina').files[0]){
            errors.spatemasina = "Nu ai adaugat poza din spate a masinii!";
        }
        if(!document.getElementById('dreaptamasina').files[0]){
            errors.dreaptamasina = "Nu ai adaugat poza din dreapta a masinii!";
        }
        if(!document.getElementById('stangamasina').files[0]){
            errors.stangamasina = "Nu ai adaugat poza din stanga a masinii!";
        }
        if(!document.getElementById('ci').files[0]){
            errors.ci = "Nu ai adaugat poza la cartea de identitate!";
        }
        if(!document.getElementById('permis').files[0]){
            errors.permis = "Nu ai adaugat poza la permis!";
        }
        if(inputValues.cnp.length === 0){
            errors.cnp = "Nu ai completat CNP-ul clientului!";
        }
        return errors;
    };

    const handleUploadImage = async () => {
        // setErrors(validateValues(inputFields)); 
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const data = new FormData();
        const fatamasina = document.getElementById('fatamasina').files[0];
        const spatemasina = document.getElementById('spatemasina').files[0];
        const dreaptamasina = document.getElementById('dreaptamasina').files[0];
        const stangamasina = document.getElementById('stangamasina').files[0];
        const ci = document.getElementById('ci').files[0];
        const permis = document.getElementById('permis').files[0];
        const client = inputFields.client;
        const cnp = inputFields.cnp;
        const telefon = inputFields.telefon;
        const adresa = inputFields.adresa;
        const localitatea = inputFields.localitatea;
        const judetul = inputFields.judetul;
        const dateStart = inputFields.dateStart;
        const dateEnd = inputFields.dateEnd;
        // const timeStart = inputFields.timeStart;
        // const timeEnd = inputFields.timeEnd;
        const price = calculatePrice(dateEnd, dateStart);
       
        data.append('fatamasina', fatamasina);
        data.append('spatemasina', spatemasina);
        data.append('dreaptamasina', dreaptamasina);
        data.append('stangamasina', stangamasina);
        data.append('ci', ci);
        data.append('permis', permis);
        data.append('rentId', props.rentId);
        data.append('client', client);
        data.append('cnp', cnp);
        data.append('telefon', telefon);
        data.append('adresa', adresa);
        data.append('localitatea', localitatea);
        data.append('judetul', judetul);
        // data.append('dateStart', dateStart);
        // data.append('dateEnd', dateEnd);
        // data.append('timeStart', timeStart);
        // data.append('timeEnd', timeEnd);
        data.append('locatiePreluare', inputFields.locatiePreluare);
        data.append('predareAltaLocatie', inputFields.predareAltaLocatie);
        data.append('tarif', price);

        setErrors(validateValues(inputFields)); 
            
        await Axios.post(`${BASE_URL}/uploadFiles`, data)
        .then((response) => {
            const imageResponse =  response.json();
            setUploadedImage(imageResponse);                  
        }).catch((err) => {
            console.log({ errorUpload: err });                
        });    

    }

    const handleClickSubmit = async() => {
        // console.log('am apelat handleClickSubmit')
        // console.log({inputFields: inputFields})

        const validateErr = validateValues(inputFields);
        setErrors(validateErr);                        
                                 
        // console.log({validateErrOBJ: Object.keys(validateErr).length})
        // console.log({validateErr: validateErr})

        // console.log({errorErrOBJ: Object.keys(errors).length});
        // console.log({errorRedirect2: errors});                  

        if(Object.keys(validateErr).length === 0){ 
            // console.log('fac upload')
            await handleUploadImage();
            const parametriPdf = getPdfParameters()
            // setParametriPdfState(parametriPdf);

            // console.log({parametriPdf: parametriPdf});
            // console.log('am setat parametrii pdf')
            // console.log('am intrat in redirect')
            navigate(`/members/pdf`, {state: parametriPdf});
        }
    }

    const handleSelectImage = (event) => {
        const file = event.target.files[0];
        console.log({ file: file });

        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
            // console.log({result: fileReader.result});
            setPreviewImage(fileReader.result);
        });
        fileReader.readAsDataURL(file);
        setFile(file);
        // setInputFields({ ...inputFields, [event.target.name]: event.target.value });
    }
    
    const handleChange =  (e) => {
        e.preventDefault();
        // console.log({ e: e });
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
        
    };
    
    // useEffect(async() => { 
    //         console.log({errorsUseEffect: errors})       
    //         if (Object.keys(errors).length === 0 && !errors) {
    //             await handleUploadImage();
    //             console.log('am intrat in useEffect')
    //         }else{
    //             setSubmitting(false);
    //             console.log('am intrat in useEffect else')
    //         }
    //     }, [errors]);
        
        const tarif = "Pret total: "+ price +" lei";

        return (
            <>
            {Object.keys(errors).length === 0 && submitting ? (
                <span className="success">Inchiriat cu succes! ✓</span>              
            ) : 
            <Button 
                variant="warning" 
                onClick={handleShow}
            >
                <i class="bi bi-cart-check"></i> 
            </Button>
            } 

            <Modal show={show} onHide={handleClose} size='lg' >
                <Form onSubmit={handleUploadImage}>
                    <Modal.Header closeButton>
                        <Modal.Title>Inchiriaza masina {nrAuto}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Container>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Data preluare</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                  
                                    <Form.Control
                                        type="text"
                                        name="dateStart"
                                        value={ inputFields.dateStart = inputFields.dateStart ? inputFields.dateStart : dateStart }
                                        onChange={handleChange}
                                        disabled
                                        // style={{ border: errors.dateStart ? "1px solid red" : null }}
                                        // inputFields.dateStart = inputFields.dateStart ? inputFields.timeStart : 
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <Form.Control
                                        type="time"
                                        name="timeStart"
                                        value={timeStart2}
                                        onChange={handleChange}
                                        disabled
                                        // style={{ border: errors.timeStart ? "1px solid red" : null }}
                                    />
                                </Col>                               
                            </Row> 

                             <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Data predare</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                   
                                    <Form.Control
                                        type="text" 
                                        name="dateEnd"
                                        value={inputFields.dateEnd = inputFields.dateEnd ? inputFields.dateEnd : dateEnd}
                                        onChange={handleChange}
                                        disabled
                                        // style={{ border: errors.dateEnd ? "1px solid red" : null }}
                                        // inputFields.dateEnd = inputFields.dateEnd ? inputFields.dateEnd : 
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <Form.Control
                                        type="time"
                                        name="timeEnd"
                                        value={timeEnd2}
                                        onChange={handleChange}
                                        disabled
                                        // style={{ border: errors.timeEnd ? "1px solid red" : null }}
                                    />
                                </Col>                               
                            </Row> 

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Locatie preluare</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Select 
                                        name="locatiePreluare"
                                        aria-label="Default select example" 
                                        onChange={handleChange}
                                        style={{ border: errors.locatiePreluare ? "1px solid red" : null }}
                                        >
                                        <option>Alege locatia de preluare</option>
                                        <option value="onesti">Onesti</option>
                                        <option value="moinesti">Moinesti</option>
                                        <option value="dofteana">Dofteana</option>
                                        <option value="livezi">Livezi</option>
                                        <option value="bacau" disabled>Bacau</option>
                                        
                                    </Form.Select>
                                    {errors.locatiePreluare ? (
                                        <p className="error">{errors.locatiePreluare}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                    
                                </Col>                               
                            </Row>
                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Locatie predare</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Select 
                                        name="predareAltaLocatie"
                                        aria-label="" 
                                        onChange={handleChange}
                                        style={{ border: errors.predareAltaLocatie ? "1px solid red" : null }}
                                        >
                                        <option>Alege locatia de predare</option>
                                        <option value="onesti">Onesti</option>
                                        <option value="moinesti">Moinesti</option>
                                        <option value="dofteana">Dofteana</option>
                                        <option value="livezi">Livezi</option>
                                        <option value="bacau" disabled>Bacau</option>
                                        
                                    </Form.Select>
                                    {errors.predareAltaLocatie ? (
                                        <p className="error">{errors.predareAltaLocatie}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>
                                    
                                </Col>                               
                            </Row>
                            <ColoredLine color="grey" />
                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adauga poza din fata a masinii</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Control 
                                        type="file"
                                        name="fatamasina"
                                        id="fatamasina"
                                        onChange={handleSelectImage}
                                        style={{ border: errors.fatamasina ? "1px solid red" : null }}
                                    />
                                    {errors.fatamasina ? (
                                        <p className="error">{errors.fatamasina}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>   
                                    {/* <Form.Label style={{ fontSize: "1.3rem", textAlign: "center" }} >Poze masina</Form.Label>     */}
                                    {/* {
                                    previewImage ? 
                                        <img src={previewImage} alt="preview-image" style={{maxWidth:100, maxHeight:100}} />
                                    :
                                        null
                                    }
                                    {
                                    uploadedImage ?
                                        <img src={uploadedImage} alt="uploaded-image" style={{maxWidth:100, maxHeight:100}} />
                                    :
                                        null
                                    }                        */}
                                </Col>                               
                            </Row>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adauga poza din spate a masinii</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Control 
                                        type="file"
                                        name="spatemasina"
                                        id="spatemasina"
                                        onChange={handleSelectImage}
                                        style={{ border: errors.spatemasina ? "1px solid red" : null }}
                                    />
                                    {errors.spatemasina ? (
                                        <p className="error">{errors.spatemasina}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>  
                                    {/* {
                                    previewImage ? 
                                        <img src={previewImage} alt="preview-image" style={{maxWidth:100, maxHeight:100}} />
                                    :
                                        null
                                    }
                                    {
                                    uploadedImage ?
                                        <img src={uploadedImage} alt="uploaded-image" style={{maxWidth:100, maxHeight:100}} />
                                    :
                                        null
                                    }                                     */}
                                </Col>                               
                            </Row>

                        <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adauga poza din dreapta masinii</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Control 
                                        type="file"
                                        name="dreaptamasina"
                                        id="dreaptamasina"
                                        onChange={handleSelectImage}
                                        style={{ border: errors.dreaptamasina ? "1px solid red" : null }}
                                    />
                                    {errors.dreaptamasina ? (
                                        <p className="error">{errors.dreaptamasina}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                    
                                </Col>                               
                            </Row>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adauga poza din stanga masinii</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Control 
                                        type="file"
                                        name="stangamasina"
                                        id="stangamasina"
                                        onChange={handleSelectImage}
                                        style={{ border: errors.stangamasina ? "1px solid red" : null }}
                                    />
                                    {errors.stangamasina ? (
                                        <p className="error">{errors.stangamasina}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                    
                                </Col>                               
                            </Row>
                            <ColoredLine color="grey" />
                             <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adauga poza la cartea de identitate </Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Control 
                                        type="file"
                                        name="ci"
                                        id="ci"
                                        onChange={handleSelectImage}
                                        style={{ border: errors.ci ? "1px solid red" : null }}
                                    />
                                    {errors.ci ? (
                                        <p className="error">{errors.ci}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>   
                                    <Form.Label style={{ fontSize: "1.3rem", textAlign: "center" }} >Acte client</Form.Label>                               
                                </Col>                               
                            </Row>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adauga poza la permis </Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>
                                    <Form.Control 
                                        type="file"
                                        name="permis"
                                        id="permis"
                                        onChange={handleSelectImage}
                                        style={{ border: errors.permis ? "1px solid red" : null }}
                                    />
                                    {errors.permis ? (
                                        <p className="error">{errors.permis}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                    
                                </Col>                               
                            </Row>
                            <ColoredLine color="grey" />

                           <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Nume si prenume</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                    
                                    <Form.Control
                                        type="text"
                                        name="client"
                                        id="client"
                                        value={inputFields.client = inputFields.client ? inputFields.client : props.client}
                                        placeholder="Numele clientului"
                                        autoFocus
                                        onChange={handleChange}
                                        style={{ border: errors.client ? "1px solid red" : null, fontSize: "0.9rem" }}
                                    />
                                    {errors.client ? (
                                        <p className="error">{errors.client}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>
                                    <Form.Control
                                        type="text"
                                        name="tarif"
                                        id="tarif"
                                        value={tarif}
                                        autoFocus
                                        disabled
                                    />
                                </Col>                               
                            </Row>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>CNP</Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                    
                                    <Form.Control
                                        type="text"
                                        name="cnp"
                                        id="cnp"
                                        value={inputFields.cnp}
                                        placeholder="CNP-ul clientului"
                                        autoFocus
                                        onChange={handleChange}
                                        style={{ border: errors.cnp ? "1px solid red" : null, fontSize: "0.9rem" }}
                                    />
                                    {errors.cnp ? (
                                        <p className="error">{errors.cnp}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>
                                    
                                </Col>                               
                            </Row>

                             <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Telefon </Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                    
                                    <Form.Control
                                        type="text"
                                        name="telefon"
                                        value={inputFields.telefon = inputFields.telefon ? inputFields.telefon : props.telefon}
                                        placeholder="Telefonul clientului"
                                        autoFocus
                                        onChange={handleChange}
                                        style={{ border: errors.telefon ? "1px solid red" : null, fontSize: "0.9rem" }}
                                    />
                                    {errors.telefon ? (
                                        <p className="error">{errors.telefon}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>
                                </Col>                               
                            </Row>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Adresa </Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                    
                                    <Form.Control
                                        type="text"
                                        name="adresa"
                                        value={inputFields.adresa}
                                        placeholder="Adresa clientului"
                                        autoFocus
                                        onChange={handleChange}
                                        style={{ border: errors.adresa ? "1px solid red" : null }}
                                    />
                                    {errors.adresa ? (
                                        <p className="error">{errors.adresa}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                                                    
                                </Col>                               
                            </Row>

                            <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Localitatea </Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                    
                                    <Form.Control
                                        type="text"
                                        name="localitatea"
                                        value={inputFields.localitatea}
                                        placeholder="Localitatea clientului"
                                        autoFocus
                                        onChange={handleChange}
                                        style={{ border: errors.localitatea ? "1px solid red" : null }}
                                    />
                                    {errors.localitatea ? (
                                        <p className="error">{errors.localitatea}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                  
                                </Col>                               
                            </Row>

                           <Row>
                                <Col xs={12} md={8}>
                                    <Form.Label>Judetul </Form.Label>
                                </Col>    
                                <Col xs={12} md={8}>                                    
                                    <Form.Control
                                        type="text"
                                        name="judetul"
                                        value={inputFields.judetul}
                                        placeholder="Judetul clientului"
                                        autoFocus
                                        onChange={handleChange}
                                        style={{ border: errors.judetul ? "1px solid red" : null }}
                                    />
                                    {errors.judetul ? (
                                        <p className="error">{errors.judetul}</p>
                                    ) : null}
                                </Col>
                                <Col xs={6} md={4}>                                  
                                </Col>                               
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Inchide
                        </Button>
                        <Button 
                            variant="success" 
                            // onClick={handleClose} 
                            type="button"
                            // onClick={handleUploadImage}
                            onClick={handleClickSubmit}
                            // onMouseDownCapture={handleClose}
                        >
                            Genereaza contract
                        </Button>
                    </Modal.Footer>
                </Form>
                </Modal>
        </>
    );
}

export default BlockCarModalButon;