import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Axios from 'axios';
import * as moment from 'moment';

export const CancelRentaCarModalButon = (props) => {

    const NewDate = (yourDate) => { const newDate = moment(yourDate).format('YYYY-MM-DD'); return newDate }
    const [show, setShow] = useState(false);
    const dateStart = NewDate(props.dateStart);
    const dateEnd = NewDate(props.dateEnd);
  
    console.log({
        props: props,
        dateStart: dateStart,
        dateEnd: dateEnd,
    })

    const [inputFields, setInputFields] = useState({
        client: props.client,
        telefon:props.telefon,
        dateStart: dateStart,
        dateEnd: dateEnd,
    });
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const validateValues = (inputValues) => {
        let errors = {};
        const curentDate = new Date();
        if (inputValues.client.length === 0) {
            errors.client = "Completeaza numele clientului";
        }
        if (inputValues.telefon.length === 0) {
            errors.telefon = "Completeaza telefonul clientului";
        }
        if (!inputValues.dateStart || inputValues.dateStart < curentDate) {
            errors.dateStart = "Data de start nu poate fi mai mica dacat ziua de astazi sau necompletata";
        }
        if (!inputValues.dateEnd || inputValues.dateEnd < curentDate || inputValues.dateEnd < inputValues.dateStart) {
            errors.dateEnd = "Data de final nu poate fi mai mica dacat data de inceput sau necompletata.";
        }
        return errors;
    };


    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    };

    const handleSubmit = (p) => {
        p.preventDefault();
        setErrors(validateValues(inputFields));
        setSubmitting(true);
    };

    const finishSubmit = async() => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const userData = {            
                rentId: props.rentId,
                // carId: props.carId,
                // client: inputFields.client,
                // telefon: inputFields.telefon,
                // dateStart: inputFields.dateStart,
                dateEnd: inputFields.dateEnd,
                // userId: props.userId,           
        };
        // console.log({ userData: userData });
        await Axios.get(`${BASE_URL}/cancelRent/${props.rentId}`, userData).then((res) => {
            // console.log({ resStatus: res.status });
        });
        let mess = new Date();
        props.onSubmitedCancel(mess);
    };

    console.log({ errors: errors });
    useEffect(() => {
        if (Object.keys(errors).length === 0 && submitting) {
            finishSubmit();
            // console.log({ mes: "ok" });
        }
    }, [errors]);

    return (
        <>
            {/* {Object.keys(errors).length === 0 && submitting ? (
                <span className="success">✓</span>
                
            ) :  */}
            <Button 
                variant="dark" 
                onClick={handleShow}
            >
                <i class='bi bi-x-square'></i>
            </Button>
            {/* } */}

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Anulezi inchirierea {props.nrAuto}?</Modal.Title>
                    </Modal.Header>
           
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Nu
                        </Button>
                        <Button variant="success" onClick={handleClose} type="submit">
                            Da
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}