import React, {useState} from "react";
import Header from "./components/header";
import Title from "./components/title";
import ContractList from "./components/contractList";
import DateFilter from "./components/dateFilter";

import '../../App.css'
import "bootstrap/dist/css/bootstrap.min.css";

import { checkIsLoggedIn } from "../Login";

const Contracte = () => {
    const [dataFromChildFilter, setLoadDataFilter] = useState({});
    const isLoggedIn = checkIsLoggedIn();
    
    console.log('Dashboard',{isLoggedIn})

    const getDataFromChildFilter = (dataFromChildFilter) => {
        setLoadDataFilter(dataFromChildFilter);
        console.log({dataFromChildFilter: dataFromChildFilter})
    }
    const userStorage = sessionStorage.getItem("user");
    
    if (userStorage) {
        var user = JSON.parse(userStorage);
        // console.log({user1: user.id})
    }
    console.log({dataFromChildFilter: dataFromChildFilter})
    return(
        <div>
            <div>
            <Header />
            <Title title = "Lista contracte"/>
            <DateFilter  onSubmitedFilter={getDataFromChildFilter}/>
            </div>
            <div class="table-responsive">
                <ContractList data ={dataFromChildFilter} />
            </div>
        </div>
    )
}

export default Contracte;