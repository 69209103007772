import React from "react";
import { useState, useEffect } from "react";
import * as moment from 'moment';
import Axios from "axios";

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {EditCarModalButon} from "../../../modal/editCarsListModal";
import { DeleteButton } from "../../../modal/deleteButtons";

const AddCarList = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [listCars, setListCars] = useState([]);
    const [dataFromChild, setDataFromChild] = useState(0);


    const getDataFromChildEdit = (data) => {
        setDataFromChild(data);
    }

    const getDataFromChildDelete = (data) => {
        setDataFromChild(data);
    }
 
    const rowColored = (item) => {
        if (item%2 === 0) {
            const bg = 'border border-1 table-light';
            return bg;
        }else{
            const bg = 'border border-1';
            return bg;
        }
    };

    useEffect(() => {
        Axios.get(`${BASE_URL}/carList/`).then((res) => {
            setListCars(res.data)
            // console.log({ cars: res.data })
        });
    }, [dataFromChild]);

    // console.log({ listCars: listCars })
    return (
        <>
        <div>
            <div className="add-car-list-container">
                <table className="table table-hover">
                    <thead>
                        <tr className="border border-1 table-dark">
                            <th scope="">#</th>
                            <th scope="">numar auto</th>
                            <th scope="">marca</th>
                            <th scope="">model</th>
                            <th scope="">culoare</th>
                            <th scope="">sasiu</th>
                            <th scope="">civ</th>
                            <th scope="">cilindree</th>
                            <th scope="">mma</th>
                            <th scope="">an</th>
                            <th scope="">tarif1</th>
                            <th scope="">tarif2</th>
                            <th scope="">tarif3</th>
                            <th scope="">:</th>

                        </tr>
                    </thead>
                    <tbody>
                        {listCars.map((item, i) =>
                            <tr className={rowColored(i)} key={i} >
                                <th scope="row">{i + 1}</th>
                                <td>{item.numarAuto}</td>
                                <td>{item.marca}</td>
                                <td>{item.model}</td>
                                <td>{item.culoare}</td>
                                <td>{item.vin}</td>
                                <td>{item.civ}</td>
                                <td>{item.cilindree}</td>
                                <td>{item.mma}</td>
                                <td>{item.anulFabricatiei} </td>
                                <td>{item.tarif1}</td>
                                <td>{item.tarif2}</td>
                                <td>{item.tarif3}</td>
                                <td>
                                    <EditCarModalButon 
                                        carId={item.carId} 
                                        nrAuto={item.numarAuto}
                                        marca={item.marca}
                                        model={item.model}
                                        culoare={item.culoare}
                                        vin={item.vin}
                                        civ={item.civ}
                                        cilindree={item.cilindree}
                                        mma={item.mma}
                                        an={item.anulFabricatiei}
                                        tarif1={item.tarif1}
                                        tarif2={item.tarif2}
                                        tarif3={item.tarif3}
                                        onSubmitedEdit={getDataFromChildEdit}
                                    />
                                    <DeleteButton
                                        carId={item.carId}
                                        onSubmitedDelete={getDataFromChildDelete}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <div className=" d-flex legenda">
                <Container fluid>
                <Row md={5} style = {{ justifyContent: 'center', paddingBottom:5, fontWeight:"500" }}>LEGENDA</Row>
                    <hr />
                    <Row md={5}  style = {{ justifyContent: 'center' }}>
                        <Col>
                            <Button
                                variant="primary"
                            >
                                <i class="bi bi-pencil-square"></i>
                            </Button>
                            <br />
                            Editeaza rezervarea
                        </Col>                        
                        <Col>
                            <Button
                                variant="danger"
                            >
                                <i class="bi bi-trash3"></i>
                            </Button><br />
                            Sterge masina
                        </Col>
                    </Row>
                    <Row md={5}  style = {{ justifyContent: 'center', borderTop: "1px solid #cccccc", marginTop:"15px", paddingTop:"15px", fontWeight:"500" }}>
                        <Col>
                            Tarif1: costul pentru 2-4 zile
                        </Col>                        
                        <Col>
                            Tarif2: costul pentru 5-14 zile
                        </Col>
                        <Col>
                            Tarif3: costul pentru 15-30 zile
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        </>
    )

}

export default AddCarList;