import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Axios from 'axios';
import * as moment from 'moment';
import { NewTime } from '../pages/members/components/cars';

export const EditRentaCarModalButon = (props) => {

    const NewDate = (yourDate) => { const newDate = moment(yourDate).format('YYYY-MM-DD'); return newDate }
    const NewTime = (yourDate) => { const newTime = moment(yourDate).format('HH:mm:ss'); return newTime }

    const [show, setShow] = useState(false);
    const dateStart = NewDate(props.dateStart);
    const dateEnd = NewDate(props.dateEnd);
    const timeStart = NewTime(props.dateStart);
    const timeEnd = NewTime(props.dateEnd);
  
    console.log({
        propsEdit: props,
        // dateStart: dateStart,
        // dateEnd: dateEnd,
        // timeStart: timeStart,
        // timeEndw: timeEnd,
    })

    const [inputFields, setInputFields] = useState({
        client: props.client,
        telefon:props.telefon,
        dateStart: dateStart,
        dateEnd: dateEnd,
        timeStart: timeStart,
        timeEnd: timeEnd,
    });
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const validateValues = (inputValues) => {
        let errors = {};
        const curentDate = new Date();
        if (inputValues.client.length === 0) {
            errors.client = "Completeaza numele clientului";
        }
        if (inputValues.telefon.length === 0) {
            errors.telefon = "Completeaza telefonul clientului";
        }
        if (!inputValues.dateStart || inputValues.dateStart < curentDate) {
            errors.dateStart = "Data de start nu poate fi mai mica dacat ziua de astazi sau necompletata";
        }
        if (!inputValues.dateEnd || inputValues.dateEnd < curentDate || inputValues.dateEnd < inputValues.dateStart) {
            errors.dateEnd = "Data de final nu poate fi mai mica dacat data de inceput sau necompletata.";
        }
        return errors;
    };


    const handleChange = (e) => {
        setInputFields({ ...inputFields, [e.target.name]: e.target.value });
    };

    const handleSubmit = (p) => {
        p.preventDefault();
        setErrors(validateValues(inputFields));
        setSubmitting(true);
        // let mess = new Date();
        // props.onSubmitedEdit(mess);
        
    };

    const finishSubmit = async() => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const userData = {            
                rentId: props.rentId,
                carId: props.carId,
                client: inputFields.client,
                telefon: inputFields.telefon,
                dateStart: inputFields.dateStart,
                dateEnd: inputFields.dateEnd,
                timeStart: inputFields.timeStart,
                timeEnd: inputFields.timeEnd,
                userId: props.userId,           
        };
        // console.log({ userData: userData });
        await Axios.put(`${BASE_URL}/editRent/${props.rentId}`, userData).then((res) => {
            // console.log({ resStatus: res.status });
        });
        let mess = new Date();
        props.onSubmitedEdit(mess);
    };

    // console.log({ errors: errors });
    useEffect(() => {
        if (submitting) {
            finishSubmit();
            // console.log({ mes: "ok" });
        }
    }, [errors]);

    return (
        <>
            {/* {submitting ? (
                <span className="success">✓</span>
                
            ) :  */}
            <Button 
                variant="primary" 
                onClick={handleShow}
            >
                <i class="bi bi-pencil-square"></i>
            </Button>
            {/* } */}

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Editeaza inchirierea {props.nrAuto}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Client</Form.Label>
                            <Form.Control
                                type="text"
                                name="client"
                                value={inputFields.client}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.client ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.client ? (
                            <p className="error">Completeaza numele clientului!</p>
                        ) : null}

                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Telefon</Form.Label>
                            <Form.Control
                                type="text"
                                name="telefon"
                                value={inputFields.telefon}
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.telefon ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.telefon ? (
                            <p className="error">Completeaza telefonul clientului!</p>
                        ) : null}

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Data start</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateStart"
                                value={inputFields.dateStart}
                                onChange={handleChange}
                                style={{ border: errors.dateStart ? "1px solid red" : null }}
                            />
                            <Form.Control
                                type="time"
                                name="timeStart"
                                value={inputFields.timeStart}
                                onChange={handleChange}
                                style={{ border: errors.timeStart ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.dateStart ? (
                            <p className="error">Data de start este mai mica decat data de astazi sau necompletata!</p>
                        ) : null}

                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea11"
                        >
                            <Form.Label>Data final</Form.Label>
                            <Form.Control
                                type="date"
                                name="dateEnd"
                                value={inputFields.dateEnd}
                                onChange={handleChange}
                                style={{ border: errors.dateEnd ? "1px solid red" : null }}
                            />
                            <Form.Control
                                type="time"
                                name="timeEnd"
                                value={inputFields.timeEnd}
                                onChange={handleChange}
                                style={{ border: errors.timeEnd ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.dateEnd ? (
                            <p className="error">Data de final este mai mica decat data de start sau necompletata!</p>
                        ) : null}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" onClick={handleClose} type="submit">
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}